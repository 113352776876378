import React, { Children } from "react";
import styles from "./DropdownButton.module.css";

export const DropdownButton = (props) => {

    //props:
    //
    // isOpen - bool, if the drop down is open or not
    // toggle - function to switch isOpen
    // buttonText - value to display in box of dropdown

    return (
        <button className={props.open ? styles.dropdownButtonOpen : styles.dropdownButtonClosed } 
                onClick={props.toggle}
                style={
                    {fontSize: props.fontSize}
                }>
            {props.buttonText}
            <div> 
                {props.open ?
                    <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="open menu icon" title="Open Menu" style={{height:props.fontSize}}/>
                    :
                    <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="closed menu icon" title="Open Menu" style={{height:props.fontSize}}/>
                }
            </div> 
        </button>
    );
}