import React, { useEffect, useState } from "react";
import styles from "./Song.module.css";
import { patchUsersubmedia } from "../../api/Api";
import ErrorHandler from "../../helpers/ErrorHandler";

export const Song = (props) => {
    // props:
    // 
    // songItem - object containting song information

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const [favorited, setFavorited] = useState(props.songItem.favorited);
    const splitTitle = processTitle();


    useEffect(() => {
        // first render processes title
        // for favorited state fake, reprocesses title when state changes
        processTitle();
    },[favorited]);

    // ----------------------------------------------------- PRE-RENDER

    async function updateUsersubmedia() {
        // updates favorites status of song

        setFavorited(!favorited) // state faking

        await patchUsersubmedia(props.songItem.usersubmediaID, { favorited : !favorited}, token)
            .then((patchedUsersubmedia) =>{
                setFavorited(patchedUsersubmedia.favorited);
            })
            .catch((error) => {
                console.log("failed to patch usersubmedia: ", error);
                setFavorited(props.songItem.favorited);

                ErrorHandler(error);
            })

    }

    function processTitle () {
        // adds star if usersubemdia

        if (props.songItem.usersubmediaID) {
            // needs star added
            let words = props.songItem.title.split(" ");

            if (words.length === 1) {
                // one word title
                return ([
                    props.songItem.overallnum + ". " + props.songItem.title,
                    null
                ])
            } 

            let lastWord = words.pop();

            const firstWords = props.songItem.overallnum + ". " + words.join(" ") + " "

            return [firstWords, lastWord];

        } else {
            return (props.songItem.overallnum + ". " + props.songItem.title);
        }
    }

    function toggleFavorited() {
        // intiates call to back
        updateUsersubmedia();
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container} onClick={toggleFavorited}>
            <div className={styles.content}>
                <span className={styles.firstWordsOfTitle}>{splitTitle[0]}</span>
                <span className={styles.lastWordOfTitle}>{splitTitle[1]}{props.songItem.usersubmediaID ? <img key="star" className={favorited ? styles.starIconFilled : styles.starIconEmpty} alt="Favorite star" /> : null}</span>
            </div>
        </section>
    )
}