import React, { useEffect, useState } from "react";
import styles from "./Cover.module.css";
import { Link } from 'react-router-dom';

import { getCover } from "../../api/Api";
import ErrorHandler from "../../helpers/ErrorHandler";

export const Cover = (props) => {

    // props:
    // 
    // mediaItem - object containing book infromation
    // loc - location of cover on page, for when a book appears more than once
    // size - S, M, L, or XL
    // link - bool, should cover be clickable

    // ----------------------------------------------------- STATE AND HOOKS

    let mediaPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;
    let token = localStorage.getItem("token");
      

    useEffect(() => {
        // tries to get cover from cache, loads from aws if fails
        let imageURL = `/covers/${props.mediaItem.mediaID}`;
        let imageRequest = new Request(imageURL);
        
        caches.open(`earmarked-cover-cache-${new Date().toISOString().slice(0,10)}`).then(async (cache) => {
            try {
                const response = await cache
                    .match(imageRequest);
                const reader = response.body.getReader();
                const stream = new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    },
                });
                const response_1 = new Response(stream);
                const blob = await response_1.blob();
                const url = URL.createObjectURL(blob);
                // renders image
                if (document.getElementById(`cover${props.mediaItem.mediaID}${props.loc}`)) { document.getElementById(`cover${props.mediaItem.mediaID}${props.loc}`).src = url; }
            } catch {
                // image not found in cache
                getCoverFromBack();
            }
        })
    }, [props.mediaItem]);

    // ----------------------------------------------------- PRE-RENDER

    async function getCoverFromBack() {
        // gets cover

        // gets cover from AWS S3 bucket
        await getCover(props.mediaItem.mediaID, token)
            .then((response) => {

                //caches image
                let resp = response.clone();
                let imageURL = `/covers/${props.mediaItem.mediaID}`;
                let imageRequest = new Request(imageURL);

                caches.open(`earmarked-cover-cache-${new Date().toISOString().slice(0,10)}`).then((cache) => {
                    cache.put(imageRequest, resp)
                });

                // displays image
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                // When no more data needs to be consumed, close the stream
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                // Enqueue the next data chunk into our target stream
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    },
                });
            })
            // Create a new response out of the stream
            .then((stream) => new Response(stream))
            // Create an object URL for the response
            .then((response) => response.blob())
            .then((blob) => URL.createObjectURL(blob))
            // Update image
            .then((url) => {
                // renders image
                if (document.getElementById(`cover${props.mediaItem.mediaID}${props.loc}`)) { 
                        document.getElementById(`cover${props.mediaItem.mediaID}${props.loc}`).src = url 
                }
            })
            .catch((err) => {
                console.error(err);
                ErrorHandler(err);
            });
    }


    function cleanUpCache () {
        // removes old caches
        caches.keys().then((keyList) => {
            keyList.map((key) => {
                if (key !== `earmarked-cover-cache-${new Date().toISOString().slice(0,10)}`) {
                    return caches.delete(key);
                }
            })
        })
    }


    // ----------------------------------------------------- RENDER

    if (props.link === false) {
        switch (props.size) {
            case "S":
                return (
                    <section className={styles.containerS}>
                        <img src="/assets/images/blankCover.jpg" id={`cover${props.mediaItem.mediaID}${props.loc}`} className={styles.bookCoverS} alt="Cover of book" />
                    </section>
                );

            case "M":
                return (
                    <section className={styles.containerM}>
                        <img src="/assets/images/blankCover.jpg" id={`cover${props.mediaItem.mediaID}${props.loc}`} className={styles.bookCoverM} alt="Cover of book" />
                    </section>
                );

            case "L":
                return (
                    <section className={styles.containerL}>
                        <img src="/assets/images/blankCover.jpg" id={`cover${props.mediaItem.mediaID}${props.loc}`} className={styles.bookCoverL} alt="Cover of book" />
                    </section>
                );

            case "XL":
                return (
                    <section className={styles.containerXL}>
                        <img src="/assets/images/blankCover.jpg" id={`cover${props.mediaItem.mediaID}${props.loc}`} className={styles.bookCoverXL} alt="Cover of book, XL" />
                    </section>
                );
        }
    } else {
        switch (props.size) {
            case "S":
                return (
                    <section className={styles.containerS}>
                        <Link to={mediaPath} className={styles.link}>
                            <img src="/assets/images/blankCover.jpg" id={`cover${props.mediaItem.mediaID}${props.loc}`} className={styles.bookCoverS} alt="Cover of book" />
                        </Link>
                    </section>
                );
    
            case "M":
                return (
                    <section className={styles.containerM}>
                        <Link to={mediaPath} className={styles.link}>
                            <img src="/assets/images/blankCover.jpg" id={`cover${props.mediaItem.mediaID}${props.loc}`} className={styles.bookCoverM} alt="Cover of book" />
                        </Link>
                    </section>
                );
    
            case "L":
                return (
                    <section className={styles.containerL}>
                        <Link to={mediaPath} className={styles.link}>
                            <img src="/assets/images/blankCover.jpg" id={`cover${props.mediaItem.mediaID}${props.loc}`} className={styles.bookCoverL} alt="Cover of book" />
                        </Link>
                    </section>
                );
    
            case "XL":
                return (
                    <section className={styles.containerXL}>
                        <Link to={mediaPath} className={styles.link}>
                            <img src="/assets/images/blankCover.jpg" id={`cover${props.mediaItem.mediaID}${props.loc}`} className={styles.bookCoverXL} alt="Cover of book, XL" />
                        </Link>
                    </section>
                );
        }
    }

}