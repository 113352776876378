import React, { useState, useEffect } from "react";

import { getMediaByLibrary } from '../../api/Api';
import { WatchBook } from '../BookTypes/WatchBook/WatchBook';

import styles from "./WatchList.module.css";
import ErrorHandler from "../../helpers/ErrorHandler";

export const WatchList = (props) => {

    // props:
    //
    // page - currently selected page

    // ----------------------------------------------------- STATE AND HOOKS

    const [mediaItems, setMediaItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const mediaObjects = makeMediaItems();

    let token = localStorage.getItem("token");

    useEffect(() => {
        fetchBooks();
    }, []);

    // ----------------------------------------------------- PRE-RENDER

    async function fetchBooks() {
        // gets book page from back

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        var pageSize = 3;
        if (window.screen.width < 500) pageSize = 2;

        await getMediaByLibrary("watch", token)
            .then((foundMedia) => {
                setMediaItems(foundMedia);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("failed to get watch media", error);
                ErrorHandler(error);
            })
    }

    function makeMediaItems() {
        //makes list of books
        if (mediaItems.length > 0) {
            let tempList = []
            for (let i = 0; i < mediaItems.length; i++) {
                tempList.push(
                    <div key={mediaItems[i].mediaID+"WATCH"}><WatchBook mediaItem={mediaItems[i]} /></div>
                )
            }
            return tempList;
        } else if (!isLoading) {
            return (<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
        }
    }

    // ----------------------------------------------------- RENDER


    return(
        <section className={styles.container}>
            <div className={ styles.content}>
               {mediaObjects}
            </div>
        </section>
    );
}