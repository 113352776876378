import React, { useState } from "react";
import { Link } from 'react-router-dom';

import styles from "./CurrentlyReadingBook.module.css";

import { endReading, patchUsermedia } from '../../../api/Api';

import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";
import ErrorHandler from "../../../helpers/ErrorHandler";

export const CurrentlyReadingBookNoProgress = (props) => {

    // props:
    // 
    // mediaItem - obejct containing media infromation

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const [isEditOpen, setIsEditOpen] = useState(false); // if edit box is open
    const [isSaving, setIsSaving] = useState(false); // is data being sent to the back

    const mediaPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;
    const seriesPath = "/series/" + props.mediaItem.series?.seriesID;

    let seriesDisplayString = (props.mediaItem.series !== null && props.mediaItem.seriesNum !== null) ?
        props.mediaItem.series?.name + " #" + props.mediaItem.seriesNum
        :
        props.mediaItem.series?.name;
    // ----------------------------------------------------- PRE-RENDER


    async function changeStatus(newStatus) {
        // sends status update to backend

        await patchUsermedia(props.mediaItem.usermediaID, { status: newStatus }, token)
            .then(() => {
                props.reload();
            })
            .catch((error) => {
                console.log(error, "failed to update media status");
                ErrorHandler(error)
            })

    }

    async function finishedReading() {
        // end reading and clears reading start date
        if (props.mediaItem.currentReading === null) return;

        const readingEndModel = {
            end : new Date,
            status : "read"
        }

        await endReading(props.mediaItem.currentReading.readingID, readingEndModel, token)
            .catch((error) =>{
                console.log("failed to end reading", error)
                ErrorHandler(error)
            });
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <Cover mediaItem={props.mediaItem} size={window.screen.width > 500 ? "L" : "M"} loc="currentlyReading" />
            <div className={styles.content}>
                <div className={styles.titleAndEditWhenNoProgress}>
                    <Link to={mediaPath} className={styles.link}>
                        <h1 className={styles.bookTitle}>{props.mediaItem.title}</h1>
                    </Link>
                    <button
                        className={styles.editButton}
                        onClick={() => setIsEditOpen(!isEditOpen)}>
                        <img src="assets/images/editIcon.svg"
                            className={styles.editButtonImage}
                            alt="Edit Icon"
                            title="Edit Progress"
                        />
                    </button>
                </div>
                {props.mediaItem.series !== null ?
                    <div>
                        <Link to={seriesPath} className={styles.link}>
                            <h3 className={styles.bookSeries}>{seriesDisplayString}</h3></Link>
                    </div> :
                    null}
                <Author authorsList={props.mediaItem.creators} size={window.screen.width > 500 ? 1 : 0} link={true} />

                {isEditOpen ? 
                    <div className={styles.editContainer}>
                       <div className={styles.markButtons}>
                                                           <button className={styles.markButton} onClick={() => {
                                                               changeStatus("read");
                                                               finishedReading();
                                                           }}
                                                           >Mark Done</button>
                                                           <button className={styles.markButton} onClick={() => {
                                                               changeStatus("DNF");
                                                               finishedReading();
                                                           }}
                                                           >Mark DNF</button>
                                                       </div> 
                    </div>
                    :
                    null

                }
            </div>
        </section>
    )
}