import React, { useEffect } from "react";
import styles from "./pages.module.css";
import { AlbumBanner } from "../comonents/Banners/AlbumBanner";
import { Footer } from "../comonents/Footer/Footer";
import { getMediaByID } from "../api/Api";
import { useParams } from "react-router-dom";
import { useMediaUpdate } from "../contexts/MediaContext";
import { useUpdateNavigationContext } from "../contexts/NavagationContext";
import ErrorHandler from "../helpers/ErrorHandler";

export const Album = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    const mediaID = useParams().albumID;

    const mediaUpdater = useMediaUpdate();

    const setCurrentLocation = useUpdateNavigationContext();
    setCurrentLocation("media")

    useEffect(() => {
        // gets album on first render
        mediaUpdater({}) // reset media to avoid flash of previous
        fetchAlbum();
    },[mediaID])
    // ----------------------------------------------------- PRE-RENDER
    async function fetchAlbum() {
        // gets album
        await getMediaByID(mediaID, token)
            .then((foundMedia) => {
                mediaUpdater(foundMedia);
            })
            .catch((error) =>{
                console.log("failed to get album", error);
                ErrorHandler(error);
            });
    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <AlbumBanner />
            </div>
            <Footer />
        </section>
    )
}