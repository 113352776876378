import React, { useState } from "react";
import styles from "./LoginForm.module.css";

import { postNewUser } from "../../api/Api.js";
import ErrorHandler from "../../helpers/ErrorHandler.js";


export const RegisterForm = (props) => {

    //props:
    //
    //putToken - function for sucessfull login

    // ----------------------------------------------------- STATE AND HOOKS

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [email, setEmail] = useState("");


    const [badUsername, setBadUsername] = useState(false);
    const [badPassword, setBadPassword] = useState(false);
    const [mismatchPassword, setMismatchPassword] = useState(false);
    const [badEmail, setBadEmail] = useState(false);

    const [takenUsername, setTakenUsername] = useState(false);


    // ----------------------------------------------------- PRE-RENDER

    //let emailRegex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
    
    const handleKeyDown = (e) => {
        // sends regestration to database if 'enter' is pressed in confirm password box
        if (e.keyCode === 13) {
            checkSumbit();
        }
    }

    function checkSumbit(){
        // checks feilds for validity
        setTakenUsername(false);
        var bad = []; // becasuse state is too slow to update

        const validateEmail = (email) => {
            return String(email)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
        };


        // username
        if (username == "") {
            setBadUsername(true)
            bad.push("username")

        } else if (username.includes(" ")) {
            setBadUsername(true)
            bad.push("username")
        } else if (!(/^\w+$/.test(username))) {
            setBadUsername(true);
            bad.push("username")

        } else {
            setBadUsername(false);
        } 
        
        // password
        if (password.length < 12 || passwordConfirm.length < 12) {
            setBadPassword(true)
            bad.push("password")
        
        } else if (password !== passwordConfirm) {
            setBadPassword(true);
            setMismatchPassword(true);
            bad.push("password")

        } else {
            setBadPassword(false);
            setMismatchPassword(false);
        }
           
        // email
        if (!validateEmail(email)) {
            setBadEmail(true)
            bad.push("email")

        } else {
            setBadEmail(false)
        }

        if (bad.length === 0) {
            // sends if nothing was wrong
            postUser();
        }
    }

    async function postUser() {
        // sends new user to back

        const credentials = {
            email : email,
            username : username,
            password : password
        }

        await postNewUser(credentials)
            .then((token) => {
                props.putToken(token);
            })
            .catch((error) => {
                if (error == "Error: Request failed with status: 409") {
                    setTakenUsername(true);
                } else {
                    console.log("failed to post new user");
                    console.log(error);

                    ErrorHandler(error);
                }
                
            })
    }

    // ----------------------------------------------------- RENDER

    return (
        <section>
            <h1 className={styles.sectionHeader}>Register</h1>
            <div className={styles.form}>
                <div className={styles.labelHeader} >
                    <h1>Email</h1>
                    {badEmail ? <h6 className={styles.badInputMessage}>Invalid Email!</h6> : null}
                    <input type="text" className={badEmail ? styles.badInput : styles.inputBox} onChange={event => setEmail(event.target.value)}/>
                </div>
                <div className={styles.labelHeader}>
                    <h1>Username</h1>
                    <h6 className={styles.info}>Username can only contain letters, numbers, and underscores</h6>
                    {badUsername ? <h6 className={styles.badInputMessage}>Invalid Username!</h6> : null}
                    {takenUsername ? <h6 className={styles.badInputMessage}>Username already taken</h6> : null}
                    <input type="text" className={badUsername ? styles.badInput : styles.inputBox} onChange={event => setUsername(event.target.value)}/>
                    
                </div>
                <div className={styles.labelHeader} >
                    <h1>Password</h1>
                    <h6 className={styles.info}>Password should be at least 12 characters long </h6>
                    {badPassword ? <h6 className={styles.badInputMessage}>{mismatchPassword ? "Passwords must match" : "Invalid password!"}</h6> : null}
                    <input type="password" className={badPassword || mismatchPassword ? styles.badInput : styles.inputBox} onChange={event => setPassword(event.target.value)}/>
                </div>
                <div className={styles.labelHeader} >
                    <h1>Confirm Password</h1>
                    {mismatchPassword ? <h6 className={styles.badInputMessage}>{mismatchPassword ? "Passwords must match!" : "Invalid password!"}</h6> : null}
                    <input type="password"  className={badPassword || mismatchPassword ? styles.badInput : styles.inputBox} onChange={event => setPasswordConfirm(event.target.value)} onKeyDown={handleKeyDown}/>
                </div>
                <button className={styles.submitButton} onClick={checkSumbit}>Submit</button>
            </div>
        </section>
    );
}