import React, { useState, useEffect } from "react";
import styles from "./MediaInfoBox.module.css";

import { Link } from "react-router-dom";
import Clamp from 'react-multiline-clamp';

import { Cover } from "../Cover/Cover";
import { Author } from "../Author/Author";

import { addMediaToLibrary, patchUsermedia } from "../../api/Api";
import { useMedia, useMediaUpdate } from "../../contexts/MediaContext";
import ErrorHandler from "../../helpers/ErrorHandler";

export const MediaInfoBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const mediaItem = useMedia();
    const mediaUpdater = useMediaUpdate();

    const [favorited, setFavorited] = useState(mediaItem.favorited);

    const formattedFull = formatDescription();
    const splitTitle = processTitle();

    useEffect(() => {
        // for favorited state fake, reprocesses title when state changes
    },[favorited]);

    // ----------------------------------------------------- PRE-RENDER
    let seriesPath = "/series/" + mediaItem.series?.seriesID;
    let seriesNumString = mediaItem.seriesNum !== null ? "#" + mediaItem.seriesNum : null;

    async function handleAddToLibrary() {
        // adds book to user's library
        await addMediaToLibrary(mediaItem.mediaID, token)
            .then((usermedia) => {
                mediaUpdater(usermedia);
            })
            .catch((error) => {
                console.log("failed to add book to library")
                console.log(error)
                ErrorHandler(error);
            })
    }

    function processTitle() {
        // splits title into two peices and adds star to second to prevent weird line wrapping
        if (mediaItem.title === null || mediaItem.title === undefined) return [null, null];
        
        let words = mediaItem.title.split(" ");
        let lastWord = words.pop();

        const firstWords = words.join(" ") + " "

        return [firstWords, lastWord]
    }

    async function saveChanges() {
        //sets book as favorite or unfavorite
        setFavorited(!favorited); // state fake for speed

        await patchUsermedia(mediaItem.usermediaID, { favorited: !favorited }, token)
            .then((book) => {
                setFavorited(!favorited);
                mediaUpdater(book);
            })
            .catch((error) => {
                console.log("failed to change status");
                console.log(error)
                ErrorHandler(error);
            })
    }

    function formatDescription () {
        //adding white space to the description
        if (mediaItem.description === null || mediaItem.description === undefined) return <div key="no description">No available description</div>;

        let formattedDescripton = []
        let descriptionPeices = mediaItem.description.split('\n'); //splits description string by newlines
        if (descriptionPeices.length === 1) { //no new lines
            return mediaItem.description;
        } else {
            for (let i = 0; i < descriptionPeices.length; i++) {
                if (!descriptionPeices[i]) { //if empty it means there was a blank line
                    formattedDescripton.push(<div key={i} className={styles.spacer}></div>)
                } else {
                    formattedDescripton.push(<div key={i}>{descriptionPeices[i]}</div>) //adds string segment to array
                }
            }
            return (formattedDescripton);
        }
    }

    function handleClick() {
        // saves favoriting to back
        saveChanges();
    }

    // ----------------------------------------------------- RENDER

    if (window.screen.width > 500) {
        // DESKTOP
        return (
            <section className={styles.container}>
                <div className={styles.coverAndTriangle}>
                    {mediaItem.usermediaID ? 
                        <div className={styles.triangle} /> 
                        : 
                        null
                    }
                    <div className={styles.bookCover}>
                        {mediaItem.usermediaID ? 
                            <div className={styles.triangleBookCover} /> 
                            : 
                            null
                        }
                        <Cover mediaItem={mediaItem} size={window.screen.width > 500 ? "XL" : "M"} loc="bookBanner" link={false}/>
                    </div>
                    {mediaItem.usermediaID ? null : <button className={styles.addToLibraryButton} onClick={handleAddToLibrary}>Add To Library</button>}
                </div>

                <div className={styles.infoAndDescription}>
                    <div className={styles.bookInfo}>
                        <div className={styles.titleAndSeries}>
                            <div className={styles.titleContainer}>
                                <button className={styles.favoriteButton} onClick={handleClick}>
                                    <span className={styles.firstWordsOfTitle}>{splitTitle[0]}</span>
                                    <span className={styles.lastWordOfTitle}>{splitTitle[1]} {mediaItem.usermediaID ?  <img key="star" className={favorited ? styles.starIconFilled : styles.starIconEmpty} alt="Favorite star" /> : null}</span>
                                </button>

                            </div>
                            {mediaItem.series != null ?
                                <div>
                                    <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{mediaItem.series?.name} {seriesNumString}</h3></Link>
                                </div> :
                                null}
                        </div>
                        <Author authorsList={mediaItem.creators} size={window.screen.width > 500 ? 2 : 1} link={true} />
                    </div>
                    <Clamp lines={4}
                        maxLines={100}
                        withTooltip={false}
                        withToggle={true}
                        showMoreElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                            </button>
                        )}
                        showLessElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                            </button>
                        )}>
                        <div className={styles.bookDescription}>{formattedFull} </div>
                    </Clamp>
                </div>
            </section>
        )
    } else {
        // MOBILE
        return (
            <section className={styles.mobileContainer}>

                <div className={styles.mobileCoverAndInfo}>
                    {mediaItem.usermediaID ?
                        <div className={styles.coverAndTriangle}>
                            <div className={styles.triangle} />
                            <div className={styles.bookCover}>
                                <div className={styles.triangleBookCover} />
                                <Cover mediaItem={mediaItem} size={window.screen.width > 500 ? "XL" : "M"} loc="bookBanner" link={false}/>
                            </div>
                        </div>
                        :
                        <div className={styles.coverImageAndButton}>
                            <div className={styles.bookCover}>
                                <Cover mediaItem={mediaItem} size={window.screen.width > 500 ? "XL" : "M"} loc="bookBanner" link={false}/>
                            </div>
                            <button className={styles.addToLibraryButton} onClick={handleAddToLibrary}>Add To Library</button>
                        </div>
                    }
                    <div className={styles.bookInfo}>
                        <div className={styles.titleAndSeries}>
                            <div className={styles.titleContainer}>
                                <button className={styles.favoriteButton} onClick={handleClick}>
                                <span className={styles.firstWordsOfTitle}>{splitTitle[0]}</span>
                                <span className={styles.lastWordOfTitle}>{splitTitle[1]} {favorited ?  <img key="star" className={favorited ? styles.starIconFilled : styles.starIconEmpty} alt="Favorite star" /> : null}</span>
                                </button>
                            </div>
                            {mediaItem.series != null ?
                                <div>
                                    <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{mediaItem.series?.name} {seriesNumString}</h3></Link>
                                </div> :
                                null}
                        </div>
                        <Author authorsList={mediaItem.creators} size={window.screen.width > 500 ? 2 : 1} link={true} />
                    </div>
                </div>
                <div className={styles.mobileDescription}>
                    <Clamp lines={4}
                        maxLines={100}
                        withTooltip={false}
                        withToggle={true}
                        showMoreElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                            </button>
                        )}
                        showLessElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                            </button>
                        )}>
                        <div className={styles.bookDescription}>{formattedFull} </div>
                    </Clamp>
                </div>
            </section>
        )

    }
}