import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

export const Footer = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token")

    // ----------------------------------------------------- PRE-RENDER

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <h4 className={styles.title}>Earmarked</h4>
                <div className={styles.body}>
                    {/* <div className={styles.link}>earmarkedapp@gmail.com</div> */}
                    <Link to="/addmedia" className={styles.link}>Add Media</Link>
                    <div className={styles.divider}>|</div>
                    {
                        token ?
                            <Link to="/bugreport" className={styles.link}>Report Bug</Link>
                            :
                            <Link target={"_blank"} to="https://github.com/capybeby/earmarked/issues" className={styles.link}>Report Bug</Link>
                    }
                    <div className={styles.divider}>|</div>
                    <Link target={"_blank"} to="https://github.com/users/capybeby/projects/3/views/1" className={styles.link}>Roadmap</Link>
                </div>

            </div>
        </section>
    )
}