import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';

import styles from "./CatalogBanner.module.css";

import { getCatalogPage, getCatalogTypePage, getGenreCatalogPage, getGenreCatalogTypePage, getGenres } from "../../api/Api";

import { CatalogBook } from "../BookTypes/CatalogBook/CatalogBook";
import { TBRbook } from "../BookTypes/TBRBook/TBRBook";

import { SmallDropdown } from "../SmallDropdown/SmallDropdown";
import { SmallDropdownItem } from "../SmallDropdown/SmallDropdownItem";

import ScrollToTop from "../../helpers/ScrollToTop";
import { SupportedTypesDictionaryMappings, SupportedTypesDisplayValuesPlural } from "../../helpers/SupportedTypes";
import { SupportedGenres } from "../../helpers/SupportedGenres";
import  ErrorHandler  from "../../helpers/ErrorHandler";

export const CatalogBanner = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const genres = SupportedGenres;

    const [searchParams, setSearchParams] = useSearchParams({ "page": "1", "genre" : 0, "type" : 0});
    const page = searchParams.get("page"); // page value
    const selectedType = searchParams.get("type"); // selected media type
    const selectedGenre = Number(searchParams.get("genre")); // selected media genre

    const [mediaSize, setMediaSize] = useState(localStorage.getItem("catalogBookSize"));

    const [filtersAreOpen, setFiltersAreOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // const hasFiltersIsClosed = (selectedGenre !== 0 || selectedType !== "0") && filtersAreOpen === false ? true : false; // determins if filters are set but not open 
    const hasFilterIndicator = (selectedGenre !== 0 || selectedType !== "0") ? true : false; // determins if filters are set but not open 

    const [mediaItems, setmediaItems] = useState([]);

    const typeObjects = makeTypeObjects();
    const genreObjects = makeGenreObjects();
    const mediaObjects = makeMediaObjects();

    let options = [
        // Options for cover size drop down
        <SmallDropdownItem key="cover" displayValue="Cover" onClick={() => changeBookSize("cover")} />,
        <SmallDropdownItem key="exp" displayValue="Expanded" onClick={() => changeBookSize("exp")} />,
    ]
    let sizeFromLocalStroageDisplayOptions = {
        // Options for converting local storage value to display value
        null: "Expanded",
        "exp": "Expanded",
        "cover": "Cover",
    }
    let colomnNumbersPerBookSize = {
        // Options for converting local storage value to content styling 
        null: styles.contentEXP,
        "exp": styles.contentEXP,
        "cover": styles.contentCOVER,
    }

    const [showEmptyShelfMessage, setShowEmptyShelfMessage] = useState(false);
    const [lastPage, setLastPage] = useState(false);

    useEffect(() => {
        // gets media page on first render and when page changes

        if (selectedType === "0") fetchMediaPage();
        else fetchMediaTypePage();

    }, [page, selectedGenre, selectedType])

    useEffect(() => {
        // scrolls to top when page changes
        ScrollToTop();
    },[page])

    useEffect(() => {
        // will trigger a rerender when mediaSize changes thus remaking media objects
    }, [mediaSize]);

    // ----------------------------------------------------- PRE-RENDER


    async function fetchMediaPage() {
        // gets a page of media from the back NOT filtered by type

        let pageSize = 12;

        if (selectedGenre === 0) {
            // pulls from all catalog media

            await getCatalogPage(page, pageSize, token)
                .then((foundMedia) => {
                    if (foundMedia.length === 0) {
                        // reached end of database
                        setShowEmptyShelfMessage(true);
                    } else {
                        if (foundMedia.length < pageSize) {
                            setLastPage(true);
                        } else {
                            setLastPage(false);
                        }
                        setShowEmptyShelfMessage(false);
                        setmediaItems(foundMedia);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("failed to fetch catalog page");
                    console.log(error);
                    setIsLoading(false);
                            
                    ErrorHandler(error)
                })
        } else {
            // pulls from catalog to match selected genre

            await getGenreCatalogPage(page, pageSize, selectedGenre, token)
                .then((foundMedia) => {
                    if (foundMedia.length === 0) {
                        // reached end of database
                        setShowEmptyShelfMessage(true);
                    } else {
                        if (foundMedia.length < pageSize) {
                            setLastPage(true);
                        } else {
                            setLastPage(false);
                        }

                        setShowEmptyShelfMessage(false);
                        setmediaItems(foundMedia);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("failed to fetch catalog genre page");
                    console.log(error);
                    setIsLoading(false);

                    ErrorHandler(error);
                })
        }
    }

    async function fetchMediaTypePage() {
        // gets page of media filtered by type (and genre if selected)

        let pageSize = 12;

        if (selectedGenre === 0) {
            // genre NOT selected
            await getCatalogTypePage(page, pageSize, SupportedTypesDictionaryMappings[selectedType], token)
                .then((foundMedia) => {
                    if (foundMedia.length === 0) {
                        // reached end of database
                        setShowEmptyShelfMessage(true);
                    } else {
                        if (foundMedia.length < pageSize) {
                            setLastPage(true);
                        } else {
                            setLastPage(false);
                        }

                        setShowEmptyShelfMessage(false);
                        setmediaItems(foundMedia);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("failed to fetch catalog type page");
                    console.log(error);
                    setIsLoading(false);

                    ErrorHandler(error);
                })
        } else {
            // genre selected
            await getGenreCatalogTypePage(page, pageSize, selectedGenre, SupportedTypesDictionaryMappings[selectedType], token)
                .then((foundMedia) => {
                    if (foundMedia.length === 0) {
                        // reached end of database
                        setShowEmptyShelfMessage(true);
                    } else {
                        if (foundMedia.length < pageSize) {
                            setLastPage(true);
                        } else {
                            setLastPage(false);
                        }
                        setShowEmptyShelfMessage(false);
                        setmediaItems(foundMedia);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log("failed to fetch catalog genre type page");
                    console.log(error);
                    setIsLoading(false);

                    ErrorHandler(error);
                })
        }
    }

    function incPage() {
        // increases page by one as caused by next button on bottom of page
        let newPage = Number(page) + 1;
        if (showEmptyShelfMessage) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        ScrollToTop();
    }

    function decPage() {
        // decreases page by one as caused by back button on bottom of page
        let newPage = Number(page) - 1;
        if (newPage < 1) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        ScrollToTop();
    }

    function makeMediaObjects() {
        // makes media objects

        let tempList = [];
        if (mediaSize === null || mediaSize === "exp") {
            tempList = mediaItems.map((media) =>
                <CatalogBook mediaItem={media} key={media.mediaID} />
            )
        } else if (mediaSize === "cover") {
            tempList = mediaItems.map((media) =>
                <TBRbook mediaItem={media} key={media.mediaID} />
            )
        }
        return tempList;
    }

    function makeGenreObjects() {
        // makes genre objects

        let temp = [];
        for (let i = 0; i < genres.length; i++) {
            temp.push(<button className={selectedGenre === genres[i].genreID ? styles.activeTypeOrGenre : styles.inactiveTypeOrGenre}
                key={i}
                onClick={() => handleGenreClick(i)}>
                {genres[i].genreNAME}
            </button>)
        }
        return temp;
    }

    function makeTypeObjects() {
        // creates objects that allow selecting of type to filter by

        let temp = [];
        for (let i = 0; i < SupportedTypesDisplayValuesPlural.length; i++) {
            temp.push(
                <button className={selectedType === SupportedTypesDisplayValuesPlural[i] ? styles.activeTypeOrGenre : styles.inactiveTypeOrGenre}
                    key={"typeselector"+i}
                    onClick={ () => handleTypeClick(i) }>
                        {SupportedTypesDisplayValuesPlural[i]}
                    </button>
            )
        }
        return temp;
    }

    function changeBookSize(newSize) {
        // changes book size

        localStorage.setItem("catalogBookSize", newSize);
        setMediaSize(newSize);
    }

    function handleGenreClick(newGenre) {
        // handles a change to selected genre

        // resets page to 1
        setSearchParams(prev => {
            prev.set("page", 1)
            return prev
        }, { replace: true });

        if (genres[newGenre].genreID === selectedGenre) {
            // unselectes a genre if the currently selected genre is clicked again

            setSearchParams(prev => {
                prev.set("genre", 0)
                return prev
            }, { replace: true });

        } else {
            // saves to search params

            setSearchParams(prev => {
                prev.set("genre", genres[newGenre].genreID)
                return prev
            }, { replace: true });
        }
    }

    function handleTypeClick(typeIndex) {
        // sets selected type 
        
        // resets page to 1
        setSearchParams(prev => {
            prev.set("page", 1)
            return prev
        }, { replace: true });

        if (SupportedTypesDisplayValuesPlural[typeIndex] === selectedType) {
            // unselects type if clicked again

            setSearchParams(prev => {
                prev.set("type", 0)
                return prev
            }, { replace: true });

        } else {
            // saves to search params

            setSearchParams(prev => {
                prev.set("type", SupportedTypesDisplayValuesPlural[typeIndex])
                return prev
            }, { replace: true });
        }
    }

    const toggleFilters = () =>{
        // toggles open filters
        setFiltersAreOpen(!filtersAreOpen);
    }

    const clear_filters = () => {
        // removes type and genre selections

        setSearchParams(prev => {
            prev.set("type", 0)
            return prev
        }, { replace: true });

        setSearchParams(prev => {
            prev.set("genre", 0)
            return prev
        }, { replace: true });

    }

    const toggle_circle_filter = (inout) => {
        // toggles existance of circle to indicate filters exist
        var element = document.getElementById("filter_circle_indicator");
        element.innerHTML = inout;
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.header}>
                <div> Catalog </div>
                <div className={styles.pageSelector}>
                    {Number(page) !== 1 ?
                        <button onClick={decPage} className={styles.carrotBackButton}><img className={styles.carrot} src="/assets/images/backIcon.svg" alt="dec page button" title="Previous Page"/></button>
                        :
                        null
                    }
                    {!showEmptyShelfMessage && !lastPage ?
                        <button onClick={incPage} className={styles.carrotNextButton}><img className={styles.carrot} src="/assets/images/nextIcon.svg" alt="inc page button" title="Next Page"/></button>
                        :
                        null
                    }

                </div>
            </div>
            <div className={styles.options}>
                <div className={styles.sizeSelector}>
                    <div className={styles.sizeTitle}>Media Size: </div>
                    <SmallDropdown buttonText={sizeFromLocalStroageDisplayOptions[mediaSize]} content={options} />
                </div>
                <div className={styles.filterSelectorContainer}>
                    <div  className={styles.filterTitleContainer}>
                        {hasFilterIndicator ? 
                            <div id="filter_circle_indicator" 
                                 onMouseEnter={ () => toggle_circle_filter("&#8855;") } 
                                 onMouseLeave={ () => toggle_circle_filter("&#9679;") } 
                                 onClick={clear_filters} className={styles.filterCircle}>
                                    &#9679;
                            </div> 
                            : 
                            null
                        } 
                        <div onClick={toggleFilters} className={styles.filterTitle}>Filters</div>
                    </div> 
                    {filtersAreOpen ? <img src="/assets/images/upCarrot.svg" className={styles.carrot} onClick={toggleFilters} title="Close Filters"/> 
                                    : <img src="/assets/images/downCarrot.svg" className={styles.carrot} onClick={toggleFilters} title="Open Filters"/>}
                </div>
            </div>
            {filtersAreOpen ?
                <div className={styles.filterContainer}>
                    <div className={styles.filterHeader}>
                        Media Types
                    </div>
                    <div className={styles.typeContainer}>
                        {typeObjects}
                    </div>

                    <div className={styles.filterHeader}>
                        Genres
                    </div>
                    <div className={styles.genreContainer}>
                        {genreObjects}
                    </div>
                </div>
                :
                null
            }

            {isLoading ?
                null
                :
                <div className={colomnNumbersPerBookSize[mediaSize]} >
                    {showEmptyShelfMessage ?
                        <div className={styles.emptyShelf}>You've reached the end of the shelf</div>
                        :
                        mediaObjects
                    }
                </div>
            }
            <div className={styles.bottomPageSelector}>
            {(!isLoading && Number(page) !== 1) ?
                <button onClick={decPage} className={styles.carrotBackButton}><img className={styles.carrot} src="/assets/images/backIcon.svg" alt="dec page button" title="Previous Page"/></button>
                :
                <div />
            }
            {(!isLoading && !showEmptyShelfMessage && !lastPage) ?
                <button onClick={incPage} className={styles.carrotNextButton}><img className={styles.carrot} src="/assets/images/nextIcon.svg" alt="inc page button" title="Next Page" /></button>
                :
                <div />
            }
            </div>
        </section>
    )
}