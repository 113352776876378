import { Footer } from "../comonents/Footer/Footer";
import { LibraryList } from "../comonents/LibraryList/LibraryList";
import { useUpdateNavigationContext } from "../contexts/NavagationContext";
import styles from "./pages.module.css";

export default function Library () {

    const setCurrentLocation = useUpdateNavigationContext();
    setCurrentLocation("library");

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <LibraryList /> 
            </div>
            <Footer />
        </div>
    );
}