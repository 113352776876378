import React from "react";
import styles from "./pages.module.css";

import { Footer } from "../comonents/Footer/Footer";
import { TagSelector } from "../comonents/TagSelector/TagSelector";
import { useUpdateNavigationContext } from "../contexts/NavagationContext";

export default function Tag () {

    // ----------------------------------------------------- STATE AND HOOKS

    const setCurrentLocation = useUpdateNavigationContext();
    setCurrentLocation("tags");
    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {/* <TagBanner /> */}
                <TagSelector />
                
            </div>
            <Footer />
        </div>
    );
}