
const SupportedGenres = [
    {
        genreID : 1,
        genreNAME: "fantasy"
    },
    {
        genreID : 2,
        genreNAME: "science fiction"
    },
    {
        genreID : 3,
        genreNAME: "fiction"
    },
    {
        genreID : 4,
        genreNAME: "mystery"
    },
    {
        genreID : 5,
        genreNAME: "nonfiction"
    },
    {
        genreID : 6,
        genreNAME: "children's"
    },
    {
        genreID : 7,
        genreNAME: "graphic novels"
    },
    {
        genreID : 8,
        genreNAME: "historical fiction"
    },
    {
        genreID : 9,
        genreNAME: "horror"
    },
    {
        genreID : 10,
        genreNAME: "poetry"
    },
    {
        genreID : 11,
        genreNAME: "romance"
    },
    {
        genreID : 12,
        genreNAME: "young adult"
    },
    {
        genreID : 13,
        genreNAME: "adventure"
    },
    {
        genreID : 14,
        genreNAME: "drama"
    },
    {
        genreID : 15,
        genreNAME: "action"
    },
    {
        genreID : 16,
        genreNAME: "anime"
    },
    {
        genreID : 17,
        genreNAME: "comedy"
    }


]

export { SupportedGenres }