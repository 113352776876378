import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import styles from "./CatalogBook.module.css";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";
import Clamp from "react-multiline-clamp";
import { addMediaToLibrary, getMediaByID } from "../../../api/Api";
import ErrorHandler from "../../../helpers/ErrorHandler";

export const CatalogBook = (props) => {

    // props:
    // 
    // mediaItem - object containing book information

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token")
    const formattedDescripton = formatDescription();

    const [inLibrary, setInLibrary] = useState(true);

    useEffect(() =>{
        // triggers rerender when mediaItem changes
        tryFetchUsermedia();
    },[props.mediaItem])

    // ----------------------------------------------------- PRE-RENDER

    let bookPath = `/${props.mediaItem.type}/`  + props.mediaItem.mediaID;
    let seriesPath = "/series/" + props.mediaItem.series?.seriesID;

    let seriesDisplayString = (props.mediaItem.series !== null && props.mediaItem.seriesNum !== null) ? 
                                        props.mediaItem.series?.name + " #" + props.mediaItem.seriesNum 
                                        : 
                                        props.mediaItem.series?.name;

    function formatDescription() {
        //adding white space to the description

        // no description
        if (props.mediaItem?.description === null) return;

        // has description
        let inProgressDescription = []
        //splits description string by newlines
        let descriptionPeices = props.mediaItem.description.split('\n'); 
        if (descriptionPeices.length === 1) { 
            //no new lines
            return props.mediaItem.description;
        } else {
            // iterates through new lines
            for (let i = 0; i < descriptionPeices.length; i++) {
                if (!descriptionPeices[i]) { 
                    //if empty it means there was a blank line
                    inProgressDescription.push(<div key={i} className={styles.spacer}></div>)
                } else {
                    //adds string segment to array
                    inProgressDescription.push(<div key={i}>{descriptionPeices[i]}</div>) 
                }
            }
            return inProgressDescription;
        }
    }

    async function tryFetchUsermedia() {
        // sees if media is already in library
        if (props.mediaItem === undefined || props.mediaItem === null) return;

        await getMediaByID(props.mediaItem.mediaID, token)
            .then((media) => {
                if (media.usermediaID) setInLibrary(true);
                else setInLibrary(false);
            })
            .catch((error) => {
                console.log("failed to fetch media", error);
                ErrorHandler(error);
            })
    }

    async function addToLibrary() {
        // adds media to library

        await addMediaToLibrary(props.mediaItem.mediaID, token)
            .then(() => {
               setInLibrary(true);
            })
            .catch((error) => {
                console.log("failed to add media to library", error);
                ErrorHandler(error);
            })
        
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <Cover mediaItem={props.mediaItem} loc = "catalog" size="L" />
            <div className={styles.bookInfo}>
                <div className={styles.titleAndAddButton}>
                    <Link to={bookPath} className={styles.link}><h1 className={styles.bookTitle}>{props.mediaItem.title}</h1></Link> 
                    { inLibrary ? 
                        null
                        :
                        <button className={styles.addButton} onClick={addToLibrary}> <img src="/assets/images/plusIcon.svg" className={styles.plus}/></button>
                    }
                   
                </div>
                
                {props.mediaItem.series != null ?
                                <div>
                                    <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{seriesDisplayString}</h3></Link>
                                </div> :
                                null}
                <div className={styles.author}><Author authorsList={props.mediaItem.creators} size={window.screen.width > 500 ? 1 : 0} link={true} /></div>
                {/* <Clamp lines={4}
                        maxLines={100}
                        withTooltip={false}
                        withToggle={true}
                        showMoreElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                            </button>
                        )}
                        showLessElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                            </button>
                        )}>
                        <div className={styles.bookDescription}>{formattedDescripton} </div>
                    </Clamp> */}
                    <Clamp lines={4}
                        maxLines={100}
                        withTooltip={false}
                        withToggle={false}
                        showMoreElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                            </button>
                        )}
                        showLessElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                            </button>
                        )}>
                        <div className={styles.bookDescription}>{formattedDescripton} </div>
                    </Clamp>
            </div>
        </section>
    )
}