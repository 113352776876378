import React, { useEffect, useState } from "react";

import styles from "./LoginForm.module.css";
import { postLogin } from "../../api/Api";
import ErrorHandler from "../../helpers/ErrorHandler";
import { Link } from "react-router-dom";

export const LoginForm = (props) => {

    // props:
    //
    // putToken - function for sucessfull login
    // setIsResettingPassword - state setter from parent

    // ----------------------------------------------------- STATE AND HOOKS

    const [badLogin, setBadLogin] = useState(false);

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    // ----------------------------------------------------- PRE-RENDER

    function handleSubmit() {
        fetchToken();
    }

    const handleKeyDown = (e) => {
        //sends credentials to database if 'enter' is pressed in password box
        if (e.keyCode === 13) {
            fetchToken();
        }
    }

    async function fetchToken() {
        // attempts logs in user
        setBadLogin(false);
        

        const credentials = {
            username: username,
            password: password
        }

        await postLogin(credentials)
            .then((generatedToken) => {
                props.putToken(generatedToken);
            })
            .catch((error) => {
                setBadLogin(true);
                console.log("failed to log in");
                console.log(error);
                
                ErrorHandler(error);
            })
    }



    // ----------------------------------------------------- RENDER
    return (
        <section>
            <h1 className={styles.sectionHeader}>Log In</h1>
            <div className={styles.form}>
                <div className={styles.labelHeader}>
                    <h1>Username</h1>
                    <input  type="text" 
                            className={styles.inputBox} 
                            onChange={event => setUsername(event.target.value)}/>
                </div>
                <div className={styles.labelHeader} >
                    <h1>Password</h1>
                    <input  type="password" 
                            className={styles.inputBox} 
                            onChange={event => setPassword(event.target.value)} 
                            onKeyDown={handleKeyDown}/>
                    <button onClick={ () => props.setIsResettingPassword(true) } className={styles.forgotButton} >Forgot password?</button>
                </div>
                
                {badLogin ?
                    <div className={styles.failer}>
                        Failed to log in, please try again
                    </div>
                    :
                    null
                }
                <div>
                    <button className={styles.submitButton} onClick={handleSubmit} onSubmit="document.body.style.zoom=100%">Submit</button>
                </div>
            </div>
        </section>
    );
}