import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import ProgressBar from "@ramonak/react-progress-bar";

import { endReading, patchUsermedia } from '../../../api/Api';

import styles from "./CurrentlyReadingBook.module.css"

import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";
import ErrorHandler from "../../../helpers/ErrorHandler";

export const CurrentlyReadingBook = (props) => {

    // props:
    //
    // mediaItem - object containing media info
    // reload - function to refresh page

    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token");

    const [isDisplay, setIsDisplay] = useState(false); // Display edit box?
    const [isSaving, setIsSaving] = useState(false) // is progress being updated

    const [pageItirator, setPageItirator] = useState(props.mediaItem.progress); // update current page
    const [renderProgress, setRenderProgress] = useState(props.mediaItem.progress); // triggers rerender when progress saved

    let progressValue = Math.round((renderProgress / props.mediaItem.numPages) * 100); // recalculates when renderProgress changes                                               

    const [progressCheck, setProgressCheck] = useState(); // Where to place progress bar lable

    const mediaPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;
    const seriesPath = "/series/" + props.mediaItem.series?.seriesID;

    let seriesDisplayString = (props.mediaItem.series !== null && props.mediaItem.seriesNum !== null) ? 
                                                                props.mediaItem.series?.name + " #" + props.mediaItem.seriesNum 
                                                                : 
                                                                props.mediaItem.series?.name;

    useEffect(() => {
        if (window.screen.width > 500) {
            // DESKTOP
            setProgressCheck(progressValue > 20);
        } else {
            // MOBILE
            setProgressCheck(progressValue > 30);
        }
    },[])

    // ----------------------------------------------------- LOGIC

    const itirateProgress = (event) => {
        // catches changes to page number
        if (event.target.value < 0) {
            // does not allow progress to be set as less than 0
            return;
        } else if (event.target.value > props.mediaItem.numPages) {
            // does not allow progress to be set as more than media length
            return;
        }

        setPageItirator(event.target.value);
    }

    async function saveProgressChanges() {
        // sends page progress to back
        let updateValue = {progress : pageItirator}
        setIsSaving(true);

        await patchUsermedia(props.mediaItem.usermediaID, updateValue, token)
            .then((updatedMedia) => {
                if (window.screen.width > 500) {
                    // DESKTOP
                    setProgressCheck(Math.round((pageItirator / props.mediaItem.numPages) * 100) > 20);
                } else {
                    // MOBILE
                    setProgressCheck(Math.round((pageItirator / props.mediaItem.numPages) * 100) > 30);
                }

                setRenderProgress(updatedMedia.progress);
                setIsDisplay(false); // closes edit box
                setIsSaving(false); // reverts save button text
            })
            .catch((error) => {
                console.log("failed to update reading progress")
                console.log(error);
                setIsSaving(false);

                ErrorHandler(error);
            })
    }

    async function changeStatus(newStatus) {
        //sends status update to backend
        await patchUsermedia(props.mediaItem.usermediaID, { status: newStatus }, token)
            .then(() => {
                props.reload();
            })
            .catch((error) => {
                console.log(error, "failed to update media status");
                ErrorHandler(error);
            })

    }

    async function finishedReading() {
        //adds reading to db when media is finished and clears reading start date

        if (props.mediaItem.currentReading === null) return;

        const readingEndModel = {
            end : new Date,
            status : "read"
        }

        await endReading(props.mediaItem.currentReading.readingID, readingEndModel, token)
            .catch((error) =>{
                console.log("failed to end reading", error)
                ErrorHandler(error);
            });
    }

    const handleKeyDown = (e) => {
        // sends page to database if 'enter' is pressed
        if (e.keyCode === 13) {
            saveProgressChanges();
        }
    }

    // ----------------------------------------------------- RENDER


    return (
        <div className={styles.container} >
            <Cover mediaItem = {props.mediaItem} size = {window.screen.width > 500 ? "L" : "M"} loc="currentlyReading"/>
            <div className={styles.content}>
                <Link to={mediaPath} className={styles.link}>
                    <h1 className={styles.bookTitle}>{props.mediaItem.title}</h1>
                </Link>
                {props.mediaItem.series !== null ?
                    <div>
                        <Link to={seriesPath} className={styles.link}>
                        <h3 className={styles.bookSeries}>{seriesDisplayString}</h3></Link>
                    </div> :
                    null}
                <Author authorsList={props.mediaItem.creators} size={window.screen.width > 500 ? 1 : 0} link={true}/>
                <div className={styles.progressContainer}>
                    {progressCheck ? (
                        <ProgressBar
                            className={styles.progressBar}
                            completed={progressValue}
                            bgColor="#7184AE"
                            labelColor="#fff"
                            labelAlignment="right"
                        />
                    ) : (
                        <ProgressBar
                            className={styles.progressBar}
                            completed={progressValue}
                            bgColor="#7184AE"
                            labelColor="#7184AE"
                            labelAlignment="outside"
                        />
                    )}
                    <button
                        className={styles.editButton}
                        onClick={() => setIsDisplay(!isDisplay)}>
                        <img src="assets/images/editIcon.svg"
                            className={styles.editButtonImage}
                            alt="Edit Icon" 
                            title="Edit Progress"
                        />
                    </button>
                    <div className={styles.editBox}>
                        {isDisplay ? (
                            <div className={styles.editContainer}>
                                <div className={styles.numberAndSave}>
                                    <input name="Page"
                                        value={pageItirator}
                                        onChange={itirateProgress}
                                        placeholder={props.mediaItem.progress}
                                        inputMode="numeric"
                                        type="number"
                                        max={props.mediaItem.numPages}
                                        className={styles.inputBox}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <button className={styles.saveButton}
                                        type="submit"
                                        onClick={isSaving ? null : saveProgressChanges}
                                    >{isSaving ? "Saving..." : "Save"}
                                    </button>
                                </div>
                                <div className={styles.markButtons}>
                                    <button className={styles.markButton} onClick={() => {
                                        changeStatus("read");
                                        finishedReading();
                                    }}
                                    >Mark Done</button>
                                    <button className={styles.markButton} onClick={() => {
                                        changeStatus("DNF");
                                        finishedReading();
                                    }}
                                    >Mark DNF</button>
                                </div>
                            </div>
                        ) 
                        : 
                        (
                            null
                        )}
                    </div>
                </div>

            </div>
        </div>
    );

}