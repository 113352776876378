import React, { useEffect, useState } from "react";
import styles from "./SearchResults.module.css";

import { useMediaUpdate } from "../../contexts/MediaContext"

import { useSearch, useSearchString } from "../../contexts/SearchContext";

import { SearchBook } from "../BookTypes/SearchTypes/SearchBook/SearchBook";
import { SearchAuthor } from "../BookTypes/SearchTypes/SeachAuthor/SearchAuthor";
import { SearchTag } from "../BookTypes/SearchTypes/SearchTag/SearchTag";
import { SearchSeries } from "../BookTypes/SearchTypes/SearchSeries/SearchSeries";
import { Link } from "react-router-dom";

export const SearchResults = (props) => {
    
    // props:
    //
    // results - list of objectItems (mediaItems, authorItems, or tagItems)

    // ----------------------------------------------------- PRE-RENDER
    const [results, setResults] = useState([]);
    const mediaUpdater = useMediaUpdate();

    const [searchType, activeIndex] = useSearch();
    const [searchString, setSearchString ] = useSearchString();

    useEffect(() => {
        makeSearchObjects();
    }, [props.results, activeIndex])

    // ----------------------------------------------------- PRE-RENDER

    function makeSearchObjects() {
        //makes search objects

        if (searchType === "Title" ) {
            let tempList = props.results.map((book, index) =>
                <div key={book.mediaID + index}> <SearchBook mediaItem={book} active={index + 1 == activeIndex} /> </div>
            )
            setResults( [
                tempList, 
                <Link to="/addmedia" className={styles.link} key="addButton"><div className={styles.addItemButton} onClick={ () => mediaUpdater({}) } > Didn't find what you were looking for? Add it! </div></Link> 
            ] )

        } else if (searchType === "Isbn") {
            let tempList = props.results.map((book, index) =>
                <div key={book.mediaID + index}> <SearchBook mediaItem={book} active={index + 1 == activeIndex} /> </div>
            )
            setResults(tempList)
        } else if (searchType === "Creator") {
            let tempList = props.results.map((author, index) =>
                <div key={author.creatorID + index}> <SearchAuthor authorItem={author} active={index + 1 == activeIndex} /> </div>
            )
            setResults(tempList)

        } else if (searchType === "Tag") {
            let tempList = props.results.map((tag, index) =>
                <div key={tag.tagID + index}> <SearchTag tagItem={tag} active={index + 1 == activeIndex} /> </div>
            )
            setResults(tempList)
            
        } else { //series
            let tempList = props.results.map((series, index) =>
                <div key={series.seriesID + index}> <SearchSeries seriesItem={series} active={index + 1 == activeIndex} /> </div>
            )
            setResults(tempList)
        }

    }

    // ----------------------------------------------------- RENDER 
    return (
        <div className={styles.container}>
            <div className={styles.results}>
                {results}
                </div>
        </div>
    );
}