import React, { useState } from "react";
import styles from "./pages.module.css";
import { useMedia } from "../contexts/MediaContext";
import { SupportedTypesDictionaryMappingsSingular, SupportedTypesDisplayValuesSingular } from "../helpers/SupportedTypes";
import { EditVideogame } from "../comonents/EditMedia/EditVideogame";
import { Footer } from "../comonents/Footer/Footer"
import { EditAlbum } from "../comonents/EditMedia/EditAlbum";
import { EditBook } from "../comonents/EditMedia/EditBook";
import { EditMovie } from "../comonents/EditMedia/EditMovie";
import { EditTVShow } from "../comonents/EditMedia/EditTVShow";
import { useUpdateNavigationContext } from "../contexts/NavagationContext";

export const EditMedia = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    const mediaItem = useMedia();

    const setCurrentLocation = useUpdateNavigationContext();
    setCurrentLocation("editmedia")

    const [selectedType, setSelectedType] = useState(SupportedTypesDictionaryMappingsSingular[mediaItem.type]);
    const typeObjects = makeTypeObjects();

    let editor = pickEditor();

    // ----------------------------------------------------- PRE-RENDER

    function makeTypeObjects() {
        // makes type objects for type selector

        let temp = [];
        for (let i = 0; i < SupportedTypesDisplayValuesSingular.length; i++) {
            temp.push (
                <button className={selectedType === SupportedTypesDisplayValuesSingular[i] ? styles.activeType : styles.inactiveType}
                        key={"typeselector"+i}
                        onClick={ () => handleTypeClick(i) }>
                    {SupportedTypesDisplayValuesSingular[i]}
                </button>
            )
        }
        return temp;
    }

    function handleTypeClick(type_index) {
        // sets selected type

        if (SupportedTypesDisplayValuesSingular[type_index] === selectedType) {
            // unselects when currently selected type is reclicked
            setSelectedType(null);
        } else {
            // selectes type
            setSelectedType(SupportedTypesDisplayValuesSingular[type_index]);
        }
    }

    function pickEditor() {
        // selects which edior to show

        switch (selectedType) {
            case "Video Game":
                return (<EditVideogame />);
            case "Album":
                return (<EditAlbum />);
            case "Book" :
                return (<EditBook />);
            case "Movie":
                return(<EditMovie />);
            case "TV Show":
                return(<EditTVShow />)
        
            default:
                break;
        }
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.header}>
                Edit {mediaItem.title}
            </div>
            <div className={styles.content}>
                <div className={styles.typeSelector}>
                    <div className={styles.typeObjects}>{typeObjects}</div>
                </div>
                <div>
                   {editor}
                </div>
            </div>
            <Footer />
        </section>
    )
}