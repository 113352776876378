import React from "react";
import styles from "./pages.module.css";

import { BugReportForm } from "../comonents/Forms/BugReportForm";
import { Footer } from "../comonents/Footer/Footer";
import { useUpdateNavigationContext } from "../contexts/NavagationContext";

export const BugReport = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const setCurrentLocation = useUpdateNavigationContext();
    setCurrentLocation("bugreport");

    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <BugReportForm />
            </div>
            <Footer />
        </section>
    )
}