import React, { useState, useEffect } from "react";
import styles from "./editmedia.module.css";
import { useMedia, useMediaUpdate } from "../../contexts/MediaContext";

import { SingleTextInput } from "./SingleTextInput/SingleTextInput";
import { GenreInput } from "./GenreInput/GenreInput";

import { SeriesSuggestion } from "../SuggestionTypes/SeriesSuggestion/SeriesSuggestion";
import { CreatorSuggestion } from "../SuggestionTypes/CreatorSuggestion/CreatorSuggestion";
import { getFromSearch, patchMedia, postCreator, postMedia, postSeries, postCover } from "../../api/Api";
import { useNavigate } from "react-router-dom";
import ErrorHandler from "../../helpers/ErrorHandler";


export const EditVideogame = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    let tokenPeices = token.split(".");
    let idAndRole = atob(tokenPeices[1]);
    let role = idAndRole.split(",")[1];

    let timeOfBackendSearch = Date.now();

    const mediaItem = useMedia();
    const mediaUpdater = useMediaUpdate();
    const navigator = useNavigate();

    // console.log(mediaItem)

    const [editingTitle, setEditingTitle] = useState(mediaItem.title);
    const [editingDescription, setEditingDescription] = useState(mediaItem.description);
    const [editingSeriesNum, setEditingSeriesNum] = useState(mediaItem.seriesNum);
    const [editingRelease, setEditingRelease] = useState(mediaItem.publication);
    const [editingNumPages, setEditingNumPages] = useState(mediaItem.numPages);
    const [editingSeasons, setEditingSeasons] = useState(mediaItem.seasons);
    const [editingParentGuide, setEditingParentGuide] = useState(mediaItem.parentGuide);
    const [editignRestricted, setEditingRestricted] = useState(false);
    const [editingSeries, setEditingSeries] = useState();
    const [editingCreator, setEditingCreator] = useState();

    const [file, setFile] = useState(null);

    const [selectedGenres, setSelectedGenres] = useState([]);
    const [selectedSeries, setSelectedSeries] = useState(null);
    const [selectedCreators, setSelectedCreators] = useState([]);

    const [searchResults, setSearchResults] = useState(null);

    // const suggestionObjects = makeSearchResults();

    const playerNumberOptions = [
        <button className={editingSeasons === 1 ? styles.activeOption : styles.inactiveOption}
            key={"playeroption1"}
            onClick={() => handleSeasonsChange(1)}>
            Single Player
        </button>,
        <button className={editingSeasons === 2 ? styles.activeOption : styles.inactiveOption}
            key={"playeroption2"}
            onClick={() => handleSeasonsChange(2)}>
            Multi Player
        </button>
    ];

    useEffect(() => {
        // sets found genres
        if (mediaItem.genres !== undefined && mediaItem.genres?.length !== 0) {
            let temp = []
            for (let i = 0; i < mediaItem.genres.length; i++) {
                temp.push(mediaItem.genres[i].genreID);
            }
            setSelectedGenres(temp);
        }

        // sets found series
        if (mediaItem.series !== undefined && mediaItem.series !== null) {
            setEditingSeries(mediaItem.series.name)
            setSelectedSeries(mediaItem.series);
        }

    }, []);

    // ----------------------------------------------------- TOUCHES BACKEND

    async function searchForSearies() {
        // searches backend for searies string

        let searchObj = {
            searchString: editingSeries,
            searchType: "Series"
        }

        await getFromSearch(searchObj, token)
            .then((results) => {
                setSearchResults(["series", results]);
            })
            .catch((error) => {
                console.log("failed to search for series", error);
                setSearchResults([]);
                ErrorHandler(error);
            })
    }

    async function searchForCreator() {
        // searches backend for searies string

        let searchObj = {
            searchString: editingCreator,
            searchType: "Creator"
        }

        await getFromSearch(searchObj, token)
            .then((results) => {
                setSearchResults(["creator", results]);
            })
            .catch((error) => {
                console.log("failed to search for creator", error);
                setSearchResults([]);
                ErrorHandler(error);
            })
    }

    async function addSeriesToDB() {
        // adds series to database if necessary

        if ( (editingSeries === "" || editingSeries === undefined) && selectedSeries === null) return null;
        else {
            return await postSeries(editingSeries, token)
                .then((series) =>{
                    return series.seriesID;
                })
                .catch((error) =>{
                    console.log("failed to post series", error);
                    ErrorHandler(error);
                })
        }
    }

    async function addCreatorsToDB() {
        // adds creators to database if necessary
        if (selectedCreators.length === 0) return [];

        let creators = [];
        for (let i = 0; i < selectedCreators.length; i++) {

            if (selectedCreators[i].creatorID === null) {
                // adds creator to database
                let postModel = {
                    name : selectedCreators[i].name,
                    first: selectedCreators[i].first,
                    last : selectedCreators[i].last
                }

                await postCreator(postModel, token)
                    .then((creator) =>{
                        creators.push(creator.creatorID);
                    })
                    .catch((error) => {
                        console.log("failed to add creator: ", i , error);
                        ErrorHandler(error);
                    });
                
            } else {
                // creator already in database
                creators.push(selectedCreators[i].creatorID);
            }
        }

        if (editingCreator !== "") {
            // add creator to database NOT selected

            let splitName = editingCreator.split(" ")
            let postModel = {
                name: editingCreator,
                first: splitName.slice(0, -1).join(" "),
                last: splitName.slice(-1).join(" ")
            }

            await postCreator(postModel, token)
                .then((creator) =>{
                    creators.push(creator.creatorID);
                })
                .catch((error) => {
                    console.log("failed to add creator" , error);
                    ErrorHandler(error);
                });
        }
        return creators;
    }

    async function addMediaToDatabase(creators, series) {
        // sends media to back
        let mediaPostModel = {
            type : "videogame",
            title : editingTitle,
            description : editingDescription,
            seriesID : series,
            seriesNum : editingSeriesNum,
            creators : creators,
            genreIDs : selectedGenres,
            restricted : editignRestricted,
            publication : editingRelease,
            numPages : editingNumPages,
            isbn : null,
            seasons : editingSeasons,
            startyear : null,
            endyear : null,
            parentguide : editingParentGuide
        }

        await postMedia(mediaPostModel, token)
            .then((media) => {
                mediaUpdater(media);
            })
            .catch((error) =>{
                console.log("failed to add media to database:", error);
                ErrorHandler(error);
            })
    }

    async function updateMedia(creators, series) {
        // sends updates to back
        let mediaPatchModel = {
            type : "videogame",
            title : editingTitle,
            description : editingDescription,
            seriesID : series,
            seriesNum : editingSeriesNum,
            creators : creators,
            genreIDs : selectedGenres,
            restricted : editignRestricted,
            publication : editingRelease,
            numPages : editingNumPages,
            seasons : editingSeasons,
            parentguide : editingParentGuide
        }

        await patchMedia(mediaItem.mediaID, mediaPatchModel, token)
            .then((media) => {
                console.log("return: " ,media)
                mediaUpdater(media);
                navigator("/vidoegame/"+media.mediaID);
            })
            .catch((error) => {
                console.log("failed to patch media", error);
                ErrorHandler(error);
            })
    }

        async function addCoverToDB(mediaID) {
            // sends cover to back
    
            if (file === null) return;
    
            let newName = "VIDEOGAME" + mediaID + ".jpg";
            let formData = new FormData();
            formData.append(`file`, file, newName);
    
            await postCover(mediaID, file, token)
                .then((success) => {
                    console.log("cover upload status: ", success);
                })
                .catch((error) =>{
                    console.log("failed to upload cover", error);
                    ErrorHandler(error);
                })
        }


    // ----------------------------------------------------- PRE-RENDER

    const handleTitleChange = (value) => {
        // catches changes to title field
        setEditingTitle(value)
    }

    const handleDescriptionChange = (value) => {
        // catches change to desctiption field
        setEditingDescription(value);
    }

    const handleSeriesNumChange = (value) => {
        // catches changes to seriesNum field
        setEditingSeriesNum(value);
    }

    const handleReleaseChange = (value) => {
        // catches changes to publication field
        setEditingRelease(value);
    }

    const handleNumPagesChange = (value) => {
        // catches changes to numPages field
        setEditingNumPages(value);
    }

    const handleSeasonsChange = (value) => {
        // catches changes to seasons field
        setEditingSeasons(value);
    }

    const handleParentGuideChange = (value) => {
        // catches changes to parentGuide field
        setEditingParentGuide(value);
    }

    const handleChangeCover = (event) => {
        // catches changes to cover upload
        setFile(event.target.files[0])
    }

    const handleChangeRestricted = () => {
        // toggles restricted
        setEditingRestricted(!editignRestricted);
    }

    const handleChangeSeries = (event) => {
        // catches changes to series name and searches for suggestions
        setEditingSeries(event.target.value);

        let currentTime = Date.now();
        if ((currentTime - timeOfBackendSearch) / 1000 >= 0.2) {
            timeOfBackendSearch = Date.now;
            searchForSearies(); // CALLS BACKEND
        }
    }

    const handleRemoveSeriesSelection = () => {
        // removes selected series
        setSelectedSeries(null);
    }

    const handleChangeCreator = (event) => {
        // catches changes to series name and searches for suggestions
        setEditingCreator(event.target.value);

        let currentTime = Date.now();
        if ((currentTime - timeOfBackendSearch) / 1000 >= 0.2) {
            timeOfBackendSearch = Date.now;
            searchForCreator(); // CALLS BACKEND
        }
    }

    const handleSelectCreator = (creator) => {
        // adds creator t0 selected creators
        if (!selectedCreators.includes(creator)) {
            setSelectedCreators(selectedCreators => [...selectedCreators, creator]);
            setEditingCreator("");
        }
    }


    const handleRemoveCreatorSelection = (index) => {
        // removes author from selection list

        let temp = [];
        for (let i = 0; i < selectedCreators.length; i++) {
            if (i !== index) {
                temp.push(selectedCreators[i]);
            }
        }
        setSelectedCreators(temp);
    }

    const handleAddUnknownCreator = () => {
        // adds author to list

        if (editingCreator !== "") {
            let splitName = editingCreator.split(" ");
            let creatorItem = {
                creatorID: null,
                name: editingCreator,
                first: splitName.slice(0, -1).join(" "),
                last: splitName.slice(-1).join(" "),
                mediaByCreator: []
            }

            setSelectedCreators(selectedCreators => [...selectedCreators, creatorItem]);
            setEditingCreator("");
        }
    }

    async function initateSubmit() {
        // starts all the peices of submitting data to backend

        const [creators, series] = await Promise.all([
            addCreatorsToDB(),
            addSeriesToDB()
        ]);

        if (mediaItem && mediaItem.mediaID !== undefined) {
            // editing media
            updateMedia(creators, series);
            addCoverToDB(mediaItem.mediaID);
            
        } else {
            // new media, must add media then cover
            const [media] = await Promise.all([
                addMediaToDatabase(creators, series)
            ]);
            addCoverToDB(media.mediaID);
            navigator("/videogame/" + media.mediaID);
        }
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <div className={styles.inputs}>
                    <SingleTextInput label="Title" setValue={handleTitleChange} foundValue={mediaItem.title} size="S" type="text" />

                    <div className={styles.restricted}>
                        <div className={styles.label}>Creator(s) </div>

                        {selectedCreators.length > 0 ?
                            <div className={styles.creatorSelections}>
                                {selectedCreators.map((creator, index) =>
                                    <div className={styles.creatorGroup} key={"selected author" + index}>
                                        <CreatorSuggestion creatorItem={creator} />
                                        <img className={styles.removeSelection}
                                            onClick={() => handleRemoveCreatorSelection(index)}
                                            alt="remove button" />
                                    </div>
                                )}
                                {/* INPUT BOX FOR NEW CREATOR */}
                                <div className={styles.creatorInputContainer}>
                                    <input type="text" key="authorInputwithselections"
                                        className={styles.creatorInput}
                                        value={editingCreator}
                                        onChange={handleChangeCreator} />
                                    <button className={styles.addButton} 
                                        onClick={handleAddUnknownCreator} 
                                        alt="add button"> 
                                    + 
                                    </button>
                                </div>
                            </div>
                            :
                            <div className={styles.creatorInputContainer}>
                                <input type="text" key="authorInputwithoutselections"
                                    className={styles.creatorInput}
                                    value={editingCreator}
                                    onChange={handleChangeCreator} />
                                <button className={styles.addButton} 
                                    onClick={handleAddUnknownCreator} 
                                    alt="add button"> 
                                    + 
                                </button>
                            </div>
                        }
                    </div>
                    
                    {(searchResults === null || searchResults === undefined) || window.screen.width > 500 ?
                        <div/>
                        :
                        <div className={styles.MOBILEsuggestions}>
                            <div className={styles.suggestions}>
                                {searchResults[0] === "series" ?
                                    <div>
                                        {searchResults[1].map((series) => <SeriesSuggestion seriesItem={series} setSelectedSeries={setSelectedSeries} />)}
                                    </div>
                                    :
                                    <div>
                                        {searchResults[1].map((creator) => <div onClick={() => handleSelectCreator(creator)}> <CreatorSuggestion creatorItem={creator} /> </div>)}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    

                    <SingleTextInput label="Description" setValue={handleDescriptionChange} foundValue={mediaItem.description} size="L" />

                    <div className={styles.restricted}>
                        <div className={styles.label}>Series</div>
                        {selectedSeries ?
                            <div className={styles.selection} key="selected series">
                                <SeriesSuggestion seriesItem={selectedSeries} />
                                <img className={styles.removeSelection} onClick={handleRemoveSeriesSelection} alt="remove button" />
                            </div>
                            :
                            <input type="text" className={styles.input} value={editingSeries} onChange={handleChangeSeries} />
                        }
                    </div>

                    <SingleTextInput label="Series Number" setValue={handleSeriesNumChange} foundValue={mediaItem.seriesNum} size="S" type="text" />
                    <SingleTextInput label="Release" setValue={handleReleaseChange} foundValue={mediaItem.publication} size="S" type="date" />
                    <SingleTextInput label="Number of Missions/Achivments" setValue={handleNumPagesChange} foundValue={mediaItem.numPages} size="S" type="number" min={0} />

                    <div className={styles.restricted}>
                        <div className={styles.label}> Number of Players</div>
                        <div className={styles.optionsContainer}>
                            {playerNumberOptions}
                        </div>

                    </div>

                    <SingleTextInput label="Parent Guide" setValue={handleParentGuideChange} foundValue={mediaItem.parentguide} size="S" />

                    <div className={styles.restricted}>
                        <div className={styles.label}>Genres</div>
                        <GenreInput selectedGenres={selectedGenres} setSelectedGenres={setSelectedGenres} />
                    </div>

                    <div className={styles.restricted}>
                        <div className={styles.label}>Cover</div>
                        <input id="input" type="file" accept=".jpg" className={styles.fileSelector} onChange={handleChangeCover} />
                    </div>

                    {
                        role === "admin" ?
                            <div className={styles.restricted}>
                                <label htmlFor="restricted" className={styles.label}> Restricted</label>
                                <input className={styles.checkBox}
                                    type="checkbox"
                                    id="restricted"
                                    name="restricted"
                                    onChange={handleChangeRestricted} />
                            </div>
                            :
                            null
                    }

                </div>

                {(searchResults === null || searchResults === undefined) || window.screen.width < 500 ?
                    <div/>
                    :
                    <div className={styles.suggestions}>
                        {searchResults[0] === "series" ?
                            <div>
                                {searchResults[1].map((series) => <SeriesSuggestion seriesItem={series} setSelectedSeries={setSelectedSeries} />)}
                            </div>
                            :
                            <div>
                                {searchResults[1].map((creator) => <div onClick={() => handleSelectCreator(creator)}> <CreatorSuggestion creatorItem={creator} /> </div>)}
                            </div>
                        }
                    </div>
                }

                <div className={styles.restricted}>
                    <div />
                    <div className={styles.buttonContainer}>
                        <button className={styles.button} onClick={ () => navigator(-1) }>Cancel</button>
                        <button className={styles.saveButton} onClick={initateSubmit}>Save</button>
                    </div>
                </div>
            </div>

        </section>
    )
}