import React from "react";
import { Link } from "react-router-dom";
import styles from "./EditionBox.module.css";
import { useMedia } from "../../contexts/MediaContext";

export const TVShowEditionBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    let tokenPeices = token.split(".");
    let idAndRole = atob(tokenPeices[1]);
    let role = idAndRole.split(",")[1];

    const mediaItem = useMedia();

    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div>
                <h4 className={styles.header}>Seasons:</h4>
                <div className={styles.value}>{mediaItem.seasons}</div>

                <h4 className={styles.header}>Episodes:</h4>
                <div className={styles.value}>{mediaItem.numPages}</div>

                <h4 className={styles.header}>Run:</h4>
                <div className={styles.value}>{mediaItem.startyear} - {mediaItem.endyear !== null ? mediaItem.endyear : "present"}</div>

                <h4 className={styles.header}>Parent Guied:</h4>
                <div className={styles.value}>{mediaItem.parentguied}</div>


                {mediaItem.genres.length > 0 ?
                    <div>
                        <h4 className={styles.header}>Genre:</h4>
                        <div className={styles.genreList}>{mediaItem.genres.map((g) => <div className={styles.genre} key={g.genreID}>{g.genreNAME}</div>)}</div>
                    </div>
                    :
                    null
                }
            </div>
            {
                role === "admin" || role === "librarian" ?
                    <div className={styles.editMediaContainer}>
                        <Link to="/editmedia">
                            <img src="/assets/images/editFile.svg" className={styles.icon} alt="Edit Media Button" title="Edit Media" />
                        </Link>
                    </div>
                    :
                    null
            }

        </section>
    )
}