import React, { useState } from "react";
import styles from "./ProgressUpdater.module.css";

import ProgressBar from "@ramonak/react-progress-bar";

import { patchUsermedia } from '../../api/Api';
import ErrorHandler from "../../helpers/ErrorHandler";

export const BookProgressUpdater = (props) => {

    // props:
    // 
    // mediaItem - object containing book infromation
    // bookUpdator - updates book 

    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token"); 

    const [pageItirator, setPageItirator] = useState(props.mediaItem.progress); //update current page

    let progressValue = Math.round((props.mediaItem.progress / props.mediaItem.numPages) * 100); // inial progress value

    const [progressCheck, setProgressCheck] = useState(progressValue > 20); //Where to place progress bar lable, inside or outside bar


    async function savePageChanges() {
        // sends updated data to backend
        updateBookProgress(pagesUpdatesObj)
    }
    // ----------------------------------------------------- PRE-RENDER

    const itiratePage = (event) => {
        //catches changes to page number
        if (event.target.value < 0) {
            // does not allow progress to be set as less than 0
            return;
        } else if (event.target.value > props.mediaItem.numPages) {
            // does not allow progress to be set as more than media length
            return;
        }
        setPageItirator(event.target.value);
    }
    
    const pagesUpdatesObj = {
        //collects data to be sent to backend
        progress: parseInt(pageItirator)
    }

    const handleKeyDown = (e) => {
        //sends page to database if 'enter' is pressed
        if (e.keyCode === 13) {
            savePageChanges();
        }
    }

    async function updateBookProgress(updateValue) {
        // sends progress to back
        await patchUsermedia(props.mediaItem.usermediaID, updateValue, token)
            .then((updatedBook) => {
                props.bookUpdater(updatedBook);
                // checks if progress % should be inside bar or out
                if (Math.round((pageItirator / props.mediaItem.numPages) * 100) > 20) {
                    setProgressCheck(true);
                } else {
                    setProgressCheck(false);
                }
            })
            .catch((error) => {
                console.log("failed to update reading progress")
                console.log(error);
                ErrorHandler(error);
            })
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            {progressCheck ? (
                        <ProgressBar
                            className={styles.progressBar}
                            completed={progressValue}
                            bgColor="#7184AE"
                            labelColor="#fff"
                            labelAlignment="right"
                        />
                    ) : (
                        <ProgressBar
                            className={styles.progressBar}
                            completed={progressValue}
                            bgColor="#7184AE"
                            labelColor="#7184AE"
                            labelAlignment="outside"
                        />
            )}
            <div className={styles.pageItiratorAndSave}>
                <input name="Page"
                    value={pageItirator}
                    onChange={itiratePage}
                    placeholder={props.mediaItem.progress}
                    inputMode="numeric"
                    type="number"
                    max={props.mediaItem.numPages}
                    className={styles.inputBox}
                    onKeyDown={handleKeyDown}
                />
                <button className={styles.saveButton}
                    type="submit"
                    onClick={savePageChanges}
                >Save
                </button>
            </div>
        </section>
    )
}