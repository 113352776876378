import React from "react";
import styles from "./GenreInput.module.css";
import { SupportedGenres } from "../../../helpers/SupportedGenres";

export const GenreInput = (props) => {

    // props:
    // 
    // selectedGenres - list of genre IDs that have been selected
    // setGenres - state var setter for selected genres

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const genreObjects = makeGenreObjects();

    // ----------------------------------------------------- PRE-RENDER

    function handleGenreClick(genre) {
        // sets genres

        if (props.selectedGenres?.includes(genre.genreID)) {
            // unselect
            let temp = [];
            for (let i = 0; i < props.selectedGenres.length; i++) {
                if (genre.genreID !== props.selectedGenres[i]) {
                    // adds all genre that are not the clicked on one
                    temp.push(props.selectedGenres[i])
                }
            }
            props.setSelectedGenres(temp);

        } else {
            // selecting
            if (props.selectedGenres !== undefined) {
                props.setSelectedGenres(selectedGenre => [...selectedGenre, genre.genreID]);
            } else {
                props.setSelectedGenres([genre.genreID]);
            }
        }
    }

    function makeGenreObjects() {
        // makes genre objects

        let temp = [];
        for (let i = 0; i < SupportedGenres.length; i++) {
            temp.push (
                <button className={props.selectedGenres?.includes(SupportedGenres[i].genreID) ? styles.activeGenre : styles.inactiveGenre} 
                        key={SupportedGenres[i].genreID}
                        onClick={() => handleGenreClick(SupportedGenres[i])}>
                    {SupportedGenres[i].genreNAME}
                </button>
            );
        }
        return temp;
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                {genreObjects}
            </div>
        </section>
    )
}