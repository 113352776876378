import React, { useState } from "react";
import styles from "./LoginForm.module.css";
import { Link } from "react-router-dom";
import { requestPasswordReset } from "../../api/Api";

import emailjs from 'emailjs-com';

export const PasswordResetRequest = (props) => {

    // props:
    // 
    // setIsRestingPassword - state setter for parent

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const [email, setEmail] = useState(null);

    // ----------------------------------------------------- PRE-RENDER

    async function handleSubmit() {
        // sends request to back for reset token
        if (email === "" || email === null) return;

        await requestPasswordReset(email)
            .then((link) =>{
                console.log(link);
                sendResetEmail(link);
            })
            .catch((error) =>{
                console.log(error, "failed to request reset token");
            })
        
    }

    function sendResetEmail(link) {
        // sets email to user

        let emailParams = {
            "email" : email,
            "link" : link
        }

        emailjs.send (
                            "earmarked",
                            "reset_password",
                            emailParams,
                            'zzKVq_gPiaT31H8J4'
                        )
            .then(() => {
                console.log("sent")
            })
            .catch((error) => {
                console.log("failed to send email");
            })
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h1 className={styles.sectionHeader}>Reset Password</h1>
            <div className={styles.form}>
                <div className={styles.labelHeader}>
                    <h1>Email</h1>
                    <input type="text"
                        className={styles.inputBox}
                        onChange={event => setEmail(event.target.value)} />
                </div>
                <div className={styles.buttons}>
                    <button onClick={ () => props.setIsResettingPassword(false)}  className={styles.submitButton}>Cancel</button>
                    <button onClick = {handleSubmit} className={styles.submitButton}>Submit</button>
                </div>
            </div>
        </section>
    )
}