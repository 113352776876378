import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./AlbumInfoBox.module.css";

import { useMedia, useMediaUpdate } from "../../contexts/MediaContext";

import { addMediaToLibrary, patchUsermedia } from "../../api/Api";

import { Cover } from "../Cover/Cover";
import { Author } from "../Author/Author";
import ErrorHandler from "../../helpers/ErrorHandler";

export const AlbumInfoBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const mediaItem = useMedia();
    const mediaUpdater = useMediaUpdate();

    const [favorited, setFavorited] = useState(mediaItem.favorited);

    const splitTitle = processTitle();

    useEffect(() => {
        // on first render and whenever favorited status changes, reprocess title
    }, [favorited])

    // ----------------------------------------------------- PRE-RENDER

    let seriesPath = "/series/" + mediaItem.series?.seriesID;
    let seriesNumString = mediaItem.seriesNum !== null ? "#" + mediaItem.seriesNum : null;

    function processTitle() {
        // splits title into two peices and adds star to second to prevent weird line wrapping
        let words = mediaItem.title.split(" ");
        let lastWord = words.pop();

        const firstWords = words.join(" ") + " "
        
        return [firstWords, lastWord];
    }

    async function handleAddToLibrary() {
        // adds book to user's library
        await addMediaToLibrary(mediaItem.mediaID, token)
            .then((userbook) => {
                mediaUpdater(userbook);
            })
            .catch((error) => {
                console.log("failed to add book to library")
                console.log(error);
                ErrorHandler(error);
            })
    }

    async function saveChanges() {
        //sets book as favorite or unfavorite
        setFavorited(!favorited); // state fake for speed

        await patchUsermedia(mediaItem.usermediaID, { favorited: !favorited }, token)
            .then((book) => {
                setFavorited(book.favorited);
                mediaUpdater(book);
            })
            .catch((error) => {
                console.log("failed to change status");
                console.log(error);
                ErrorHandler(error);
            })
    }

    function handleClick() {
        // saves favoriting to back
        saveChanges();
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={window.screen.width > 500 ? styles.container : styles.mobileContainer}>
                {mediaItem.usermediaID ?
                    <div className={styles.coverAndTriangle}>
                        <div className={styles.triangle} />
                        <div className={styles.bookCover}>
                            <div className={styles.triangleBookCover} />
                            <Cover mediaItem={mediaItem} size={"XL"} loc="bookBanner" />
                        </div>
                    </div>
                    :
                    <div className={styles.coverImageAndButton} >
                        <div className={styles.bookCover}>
                            <Cover mediaItem={mediaItem} size={"XL"} loc="bookBanner" />
                        </div>
                        <button className={styles.addToLibraryButton} onClick={handleAddToLibrary}>Add To Library</button>
                    </div>
                }
                <div className={styles.mediaInfo}>
                    <div className={styles.titleAndSeries}>
                        <div className={styles.titleContainer}>
                            <button className={styles.favoriteButton} onClick={handleClick}>
                                <span className={styles.firstWordsOfTitle}>{splitTitle[0]}</span>
                                <span className={styles.lastWordOfTitle}>{splitTitle[1]} {favorited ? <img key="star" className={favorited ? styles.starIconFilled : styles.starIconEmpty} alt="Favorite star" /> : null}</span>
                            </button>

                        </div>
                        {mediaItem.series != null ?
                            <div>
                                <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{mediaItem.series?.name} {seriesNumString}</h3></Link>
                            </div> :
                            null}
                    </div>
                    <Author authorsList={mediaItem.creators} size={window.screen.width > 500 ? 2 : 1} link={true} alignRightOveride={true}/>
                </div>
        </section>
    )
}