import React, { useEffect, useState } from "react";
import styles from "./SingleTextInput.module.css";

import Textarea from 'rc-textarea';

export const SingleTextInput = (props) => {

    // props:
    // 
    // label - text to display above input box
    // setValue - var setter from parent to catch input
    // foundValue - data from selected option
    // size - determins input or textbox, S or L
    // type - input type
    // min - minimum value


    // ----------------------------------------------------- STATE AND HOOKS

    const [input, setInput] = useState(props.foundValue);

    useEffect(() =>{
        // adds info from book option selection
        setInput(props.foundValue);
    },[props.foundValue])

    // ----------------------------------------------------- PRE-RENDER

    const handleChangeValue = (event) =>{
        // catches changes to input value
        setInput(event.target.value);
        props.setValue(event.target.value);
    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h1 className={styles.label}>{props.label} </h1>
            {
                props.size === "S" ?
                    <input type={props.type} className={styles.input} value={input} onChange={handleChangeValue} min={props.min} />
                    :
                    <Textarea type="text" autoSize={{ minRows: 5 }} className={styles.input} value={input} onChange={handleChangeValue} />   
            }
        </section>
    )
}