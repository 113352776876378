import React, { useEffect, useState } from 'react';
import styles from "./CurrentlyReading.module.css";
import { getMediaByLibrary } from '../../api/Api';
import { Loader } from '../Loader/Loader';

import { CurrentlyReadingBook } from "../BookTypes/CurrentlyReadingBook/CurrentlyReadingBook";
import { CurrentlyReadingBookNoProgress } from '../BookTypes/CurrentlyReadingBook/CurrentlyReadingBookNoProgress';
import ErrorHandler from '../../helpers/ErrorHandler';

export const CurrentlyReading = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const [mediaItems, setMediaItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const mediaObjects = makeMediaObjects();

    useEffect(() => {
        //runs on first render
        fetchMedia();
    }, []);

    useEffect(() => {
        // triggers rerender when media arrives
    }, [mediaItems])


    // ----------------------------------------------------- LOGIC

    function reloadBooks() {
        // function for children to refresh page
        fetchMedia();
    }

    async function fetchMedia() {
        // gets currently reading books
        // determining access level

        await getMediaByLibrary("currentlyReading", token)
            .then((foundMedia) => {
                try {
                    // trys to sorts media by marked as reading order
                    let sortedMedia = foundMedia.sort(function (a, b) {
                        if (a?.currentReading.readingID > b?.currentReading.readingID) {
                            return -1;
                        }
                        else return 1;
                    });
                    setMediaItems(sortedMedia);
                } catch {
                    setMediaItems(foundMedia);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("failed to fetch media", error);
                ErrorHandler(error);
            });

    }

    function makeMediaObjects() {
        // makes media items 
        let temp = [];
        for (let i = 0; i < mediaItems.length; i++) {

            if (mediaItems[i].numPages=== 0) {
                // for items with NO progress tracking
                temp.push(
                    <CurrentlyReadingBookNoProgress mediaItem={mediaItems[i]} reload={reloadBooks} key={mediaItems[i].type + mediaItems[i].mediaID}/>
                )
            } else {
                temp.push(
                    <CurrentlyReadingBook mediaItem={mediaItems[i]} reload={reloadBooks} key={mediaItems[i].type + mediaItems[i].mediaID} />
                )
            }
        }
        return temp;
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h1 className={styles.sectionTitle}>Current</h1>
            <div>
                { isLoading ?
                    <></>
                    :
                    <div className={styles.content}>
                        {mediaObjects}
                    </div>
                }
            </div>
        </section>

    );

}