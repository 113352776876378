/* Front page banner */
import React from "react";
import styles from "./HomeBanner.module.css";
import { CurrentlyReading } from "../CurrentlyReading/CurrentlyReading";

import { TBRorWatch } from "../TBRorWatch/TBRorWatch";

export const HomeBanner = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.currentMedia}>
                    <CurrentlyReading />
                </div>
                <TBRorWatch />
            </div>
        </div>
    );
}