import React, { useEffect } from 'react';
import styles from "./pages.module.css";

import { useMediaUpdate } from '../contexts/MediaContext';

import { HomeBanner } from '../comonents/Banners/HomeBanner';
import { FavoriteBooks } from '../comonents/FavoriteBooks/FavoriteBooks';
import { Footer } from '../comonents/Footer/Footer';
import { useUpdateNavigationContext } from '../contexts/NavagationContext';


export default function Home() {

    // ----------------------------------------------------- STATE AND HOOKS

    const mediaUpdater = useMediaUpdate();
    const setCurrentLocation = useUpdateNavigationContext();
    setCurrentLocation("home");
    
    useEffect(() => {
        //resets book context to avoid flash of last loaded book
        mediaUpdater({});
    }, [])

    // ----------------------------------------------------- RENDER
    return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <HomeBanner />
                    <FavoriteBooks />
                </div>
                <Footer />
            </div>
    );
}