// context to track the current page 
// enables nav bar to change when any link is pressed

import React, { useState, useContext, createContext } from "react";

export const NavigationContext = createContext(null);
export const UpdateNavigationContext = createContext(null);

export function useNavigationContext () {
    // allows reading of current location

    const nav = useContext(NavigationContext);
    if (nav === undefined) {
        console.log("an error has occured, useNavigationContext is undefined");
    }
    return nav;
}

export function useUpdateNavigationContext () {
    // allows writing of current location

    const nav = useContext(UpdateNavigationContext);
    if (nav === undefined) {
        console.log("an error has occured, useUpdateNavigationContext is undefined");
    }
    return nav;
}


export function NavigationContextProvider ( {children} ) {
    
    const [currentLocation, setCurrentLocation] = useState({});

    const setLocation = (location) => {
        // sets location
        setCurrentLocation(location);
    }

    return (
        <NavigationContext.Provider value={currentLocation}>
            <UpdateNavigationContext.Provider value={setLocation}>
                {children}
            </UpdateNavigationContext.Provider>
        </NavigationContext.Provider>
    )
}
