import React, { useEffect, useState } from "react";

import Markdown from 'react-markdown'
import Textarea from 'rc-textarea';

import styles from "./ReviewBox.module.css";

import { patchUsermedia } from '../../api/Api';
import { useMedia, useMediaUpdate } from "../../contexts/MediaContext";
import { MarkdownInfo } from "../MarkdownInfo/MarkdownInfo";
import ErrorHandler from "../../helpers/ErrorHandler";


export const ReviewBox = () => {
    
    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const mediaItem = useMedia();
    const mediaUpdater = useMediaUpdate();

    const [isEditingReview, setIsEditingReview] = useState(false); // is edit box open?
    const [isInfoOpen, setIsInfoOpen] = useState(false); // is markdown info open?
    const [isSaving, setIsSaving] = useState(false); // are changes being sent to back?

    const [editedReview, setEditedReview] = useState(mediaItem.review);
    const formatted = formatReview();

    // ----------------------------------------------------- LOGIC

    const updateReviewEditing = () => { 
        // opens or closes textarea
        setIsEditingReview(!isEditingReview);
    }

    const catchChanges = (event) => { 
        // catches the changes in textarea
        setEditedReview(event.target.value);
    }

    const reviewUpdateObj = { 
        // object to be sent to db
        review: editedReview
    }

    const handleInfoClick = () => {
        // toggles isInfoOpen
        setIsInfoOpen(!isInfoOpen)
    }

    const cancelChanges = () => {
        // closes review box
        setEditedReview(mediaItem.review);
        setIsEditingReview(false);
    }

    async function sendChanges() { 
        // sends changed review to database
        
        setIsSaving(true);
        if (editedReview === null) {
            // closes editing box if no review entered
            setIsEditingReview(false);
        } else {
            // sends edited review to back
            await patchUsermedia(mediaItem.usermediaID, reviewUpdateObj, token)
                .then((media) => {
                    mediaUpdater(media);
                    setIsEditingReview(false)
                    setIsSaving(false);
                })
                .catch((error) => {
                    console.log("failed to patch review");
                    console.log(error)
                    setIsSaving(false);

                    ErrorHandler(error);
                })
        }
    }


    function formatReview() {
        // adding white space to the review
        if (mediaItem.review === null) return null;

        let formattedReview = []
        let reviewPeices = mediaItem.review.split('\n'); // splits review string by newlines
        for (let i = 0; i < reviewPeices.length; i++) {
            if (!reviewPeices[i]) { // if empty it means there was a blank line
                formattedReview.push(<div key={i} className={styles.spacer}></div>)
            } else {
                formattedReview.push(<Markdown key={i} className={styles.reviewPeiece}>{reviewPeices[i]}</Markdown>) //adds string segment to array
            }
        }
        return formattedReview;
    }


    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.reviewContainer}>
            <div className={styles.titleAndButtons}>
                <h3 className={styles.infoTitle}>Review</h3>
                <div className={styles.headerbuttons}>
                    <button
                        className={styles.editButton}
                        onClick = {handleInfoClick}>
                        <img src="/assets/images/info.svg"
                            id="InfoIcon"
                            className={styles.editButtonImage}
                            alt="Info Icon" 
                            title="Markdown Notation"/>
                    </button>
                    <button
                        className={styles.editButton}
                        onClick={updateReviewEditing}>
                        <img src="/assets/images/editIcon.svg"
                            className={styles.editButtonImage}
                            alt="Edit Icon" 
                            title="Edit Review"/>
                    </button>
                </div>
            </div>
            {isInfoOpen ? 
                <div className={styles.markdownInfo} > <MarkdownInfo toggle = {setIsInfoOpen} isOpen={isInfoOpen}/> </div>
                :
                null
            }
            {isEditingReview ? (
                <div className={styles.editingReviewArea}>
                    <Textarea name="Review"
                        autoSize={ {minRows: 10} }
                        className={styles.reviewTextBox}
                        defaultValue={editedReview}
                        autoFocus={true}
                        placeholder="Review"
                        onChange={catchChanges}
                    />
                    <div className={styles.footerButtons}>
                        <button className={styles.cancelButton} onClick={cancelChanges}>Cancel</button>
                        <button className={styles.submitButton} onClick={sendChanges}>{isSaving ? "Saving..." : "Save"}</button>
                    </div>
                    
                </div>
            ) : (
                <div className={styles.review}>{formatted}</div>
            )}
        </div>
    )
}