import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"
import styles from "./NavBar.module.css";
import { Search } from "../Search/Search";
import { ProfileDropdown } from "../ProfileDropdown/ProfileDropdown";
import { useNavigationContext, useUpdateNavigationContext } from "../../contexts/NavagationContext";

export const NavBar = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    var tokenPeices = [];
    var decodedUserID = [];

    if (token != null) {
        tokenPeices = token.split(".");
        decodedUserID = atob(tokenPeices[1]);
    }

    const currentLocation = useNavigationContext();
    const updateCurrentLocation = useUpdateNavigationContext();

    // MOBILE state vatrs
    const [isHamburgerOpen, setIsHambergerOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);

    // ----------------------------------------------------- PRE-RENDER

    const toggleIsHambergerOpen = () => {
        // toggles hamberger menu
        setIsHambergerOpen(!isHamburgerOpen);

        setIsProfileOpen(false);
        setIsSearchOpen(false);
    }

    const toggleIsSearchOpen = () => {
        // toggles search menu
        setIsSearchOpen(!isSearchOpen);

        setIsHambergerOpen(false);
        setIsProfileOpen(false);
    }

    const closeOverlays = () => {
        // closes all overlays
        setIsHambergerOpen(false);
        setIsProfileOpen(false);
        setIsSearchOpen(false);
    }

    function makeLinks() {
        // makes link objects 

        if (window.screen.width < 500) {
            // MOBILE
            return (
                <div className={styles.mobileLinksContainer}>
                    <div className={styles.movileHomeLink}>
                        <Link to="/" onClick={closeOverlays}>
                            <img src="/cat.png" className={styles.mobileIcon} />
                        </Link>
                    </div>
                    <div className={styles.mobileLinks}>
                        <img src={isSearchOpen ? "/assets/images/searchIconGray.svg" : "/assets/images/searchIconLight.svg"}
                            className={styles.searchIcon}
                            onClick={toggleIsSearchOpen}
                            alt="search icon" />
                        <img src={isHamburgerOpen ? "/assets/images/hamburgerLight.svg" : "/assets/images/hamburger.svg"}
                            className={styles.hamburger}
                            onClick={toggleIsHambergerOpen}
                            alt="hamberger menu" />
                    </div>
                </div>
            )
        } else if (window.screen.width > 500) {
            // DESKTOP 
            return (
                <div className={styles.content}>
                    <div className={styles.links}>
                        <Link to="/"
                            className={styles.icon}
                            onClick={ () => updateCurrentLocation("home")}>
                            <img src="/cat.png" className={styles.icon} />
                        </Link>
                        <Link to="/"
                            className={currentLocation === "home" ? styles.activeNavbarLink : styles.navbarLink}
                            onClick={ () => updateCurrentLocation("home")}>
                            Home
                        </Link>
                        <Link to="/library?page=1&library=read&type=0"
                            className={currentLocation === "library" ? styles.activeNavbarLink : styles.navbarLink}
                            onClick={ () => updateCurrentLocation("library")}>
                            Library
                        </Link>
                        <Link to='/tags'
                            className={currentLocation === "tags" ? styles.activeNavbarLink : styles.navbarLink}
                            onClick={ () => updateCurrentLocation("tags")}>
                            Tags
                        </Link>
                        <Link to='/catalog?page=1&genre=0&type=0'
                            className={currentLocation === "catalog" ? styles.activeNavbarLink : styles.navbarLink}
                            onClick={ () => updateCurrentLocation("catalog")}>
                            Catalog
                        </Link>
                    </div>
                    <div className={styles.searchAndProfile}>
                        <Search />
                        <div className={styles.profile}><ProfileDropdown /></div>
                    </div>
                </div>
            )
        }
    }

    const links = makeLinks();

    // ----------------------------------------------------- RENDER

    if (window.screen.width > 500) {
        // DESKTOP
        return (
            <section className={styles.container}>
                {links}
            </section>
        )
    } else {
        // MOBILE
        return (
            <section className={styles.container}
                style={(isHamburgerOpen || isProfileOpen || isSearchOpen) ?
                    { position: "sticky", top: 0, zIndex: 6 }
                    :
                    null}
            >
                {links}

                {isSearchOpen ?
                    <div className={styles.overlay} id="overlay">
                        <div className={styles.overlaySearchContent} onClick={closeOverlays}>
                            <Search />
                        </div>
                    </div>
                    :
                    null
                }

                {isHamburgerOpen ?
                    <div className={styles.overlay} id="overlay" onClick={toggleIsHambergerOpen}>
                        <div className={styles.overlayListContent}>
                            <Link to="/"
                                className={styles.navbarLink}
                                onClick={toggleIsHambergerOpen}>
                                Home
                            </Link>
                            <Link to="/library?page=1&library=read&type=0"
                                className={styles.navbarLink}
                                onClick={toggleIsHambergerOpen}>
                                Library
                            </Link>
                            <Link to='/tags'
                                className={styles.navbarLink}
                                onClick={toggleIsHambergerOpen}>
                                Tags
                            </Link>
                            <Link to='/catalog?page=1&genre=0&type=0'
                                className={styles.navbarLink}
                                onClick={toggleIsHambergerOpen}>
                                Catalog
                            </Link>
                            <Link to={`/account/${decodedUserID}`}
                                className={styles.profileLink}
                                onClick={toggleIsHambergerOpen}>
                                Account
                            </Link>
                        </div>
                    </div>
                    :
                    null
                }
            </section>
        )
    }

}