import React, { useState, useEffect } from "react";
import styles from "./RecentlyAdded.module.css";

import { getMediaPage } from '../../api/Api';
import { TBRbook } from "../BookTypes/TBRBook/TBRBook";
import { Loader } from "../Loader/Loader";
import ErrorHandler from "../../helpers/ErrorHandler";

export const RecentlyAdded = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    const [isLoading, setIsLoading] = useState(true);

    const [media, setMedia] = useState([]);

    const mediaObjects = makeMediaObjects();


    // number of medias across based on window size;
    let pageSize = window.innerWidth > 500 ? 
                    Math.floor(window.innerWidth / 198) 
                    : 
                    Math.floor(window.innerWidth / 150); 
    let tempCSS = "1fr ";
    let CSSModifier = tempCSS.repeat(pageSize);


    useEffect(() => {
        // first render, gets media
        fetchMedia();
    }, []);

    // ----------------------------------------------------- PRE-RENDER

    async function fetchMedia() {
        // gets book page from back

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        await getMediaPage(1, pageSize, "recent", "usermediaID", false, token)
            .then((foundMedia) => {
                setMedia(foundMedia);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("failed to get recently added media", error)
                setIsLoading(false);
                ErrorHandler(error);
            })
    }

    function makeMediaObjects() {
        // makes book items
        if ( media.length > 0) {
            let tempList = []
            for (let i = 0; i < media.length; i++) {
                tempList.push(
                    <div key={media[i].mediaID + "RECENT"}><TBRbook mediaItem={media[i]} /></div>
                )
            }
            return tempList;
        }
        else {
            return <div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>
        }

    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            {isLoading ?
                <div className={styles.loader}>
                    <Loader />
                </div>
                :
                <div className={styles.content} style={{ gridTemplateColumns: CSSModifier }}>
                    {mediaObjects}
                </div>
            }
        </section>
    )
}