import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import styles from "./pages.module.css";

import { getSerieByID } from "../api/Api";
import { SeriesBanner } from "../comonents/Banners/SeriesBanner";
import { Footer } from "../comonents/Footer/Footer";
import { useUpdateNavigationContext } from "../contexts/NavagationContext";
import ErrorHandler from "../helpers/ErrorHandler";

export default function Series() {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const setCurrentLocation = useUpdateNavigationContext();
    setCurrentLocation("series");

    const seriesID = useParams().id;
    const [series, setSeries] = useState({});

    useEffect(() => {
        fetchSeries()
    },[seriesID]);

    // ----------------------------------------------------- PRE-RENDER

    async function fetchSeries(){
        //gets series object from back
        await getSerieByID(seriesID, token)
            .then((foundSeries) => {
                setSeries(foundSeries)
                console.log(foundSeries)
            })
            .catch((error) => {
                console.log("failed to find series")
                console.log(error);

                ErrorHandler(error);
            })
    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <SeriesBanner seriesItem={series} />
            </div>
            <Footer />
        </div>
    )
}