//api.js

const SERVER_URL = 'https://earmarked.app/api/v1/earmarked';
// const SERVER_URL = 'http://localhost:8080/api/v1/earmarked';

async function getMediaByID(mediaID, token) {
    // gets media (or usermedia if avaliable) by ID
    try {
        const response = await fetch(`${SERVER_URL}/medias/${mediaID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get media: ${error.message}`);
    }
}

async function getUsermediaByID(usermediaID, token) {
    // gets usermedia by ID
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/${usermediaID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get usermedia: ${error.message}`);
    }
}

async function getMediaByLibrary(library, token) {
    // gets all media in a given library
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/library?library=${library}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get media by library: ${error.message}`);
    }
}

async function getMediaPage(pageNumber, pageSize, library, sortBy, asc, token) {
    // limited access
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/library/page?pageNumber=${pageNumber}&pageSize=${pageSize}&library=${library}&sortBy=${sortBy}&asc=${asc}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch book: ${error.message}`)
    }
}

async function getMediaTypePage(pageNumber, pageSize, library, sortBy, asc, type, token) {
    // limited access
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/library/page/type?pageNumber=${pageNumber}&pageSize=${pageSize}&library=${library}&sortBy=${sortBy}&asc=${asc}&type=${type}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get media type page: ${error.message}`)
    }
}

async function postMedia(mediaPostModel, token) {
    // adds new media to database
    try {
        const response = await fetch(`${SERVER_URL}/medias`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(mediaPostModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to add media to library: ${error.message}`)
    }
}

async function patchMedia(mediaID, mediaPatchModel, token) {
    // updates existing media
    try {
        const response = await fetch(`${SERVER_URL}/medias/${mediaID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(mediaPatchModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch media: ${error.message}`)
    }
}

async function patchUsermedia(usermediaID, updateModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/${usermediaID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(updateModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch media: ${error.message}`)
    }
}

async function patchUsersubmedia(usersubmediaID, updateModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/usersubmedias/${usersubmediaID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(updateModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch usersubmedia: ${error.message}`)
    }
}

async function addMediaToLibrary(mediaID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/usermedias?mediaID=${mediaID}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to add media to library: ${error.message}`)
    }
}

async function getTotalItems(library, token) {
    // gets number of items in a given library
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/library/page/total?library=${library}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get item total: ${error.message}`)
    }
}

async function getTotalTypeItems(library, type, token) {
    // gets number of items in a given library
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/library/page/type/total?library=${library}&type=${type}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`},
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get total for type page: ${error.message}`)
    }
}

async function getCover(mediaID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/medias/cover/${mediaID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get cover: ${error.message}`)
    }
}

async function putTagOnMedia(usermediaID, id, token) {
    // adds tag to media
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/${usermediaID}/tags/${id}`, {
            method: "PUT",
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to put tag on media: ${error.message}`)
    }
}

async function getSerieByID(seriesID, token) {
    try{
        const response = await fetch(`${SERVER_URL}/series/${seriesID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get series: ${error.message}`)
    }
}

async function getCreator(creatorID, token) {
    try{
        const response = await fetch(`${SERVER_URL}/creators/${creatorID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get author: ${error.message}`)
    }
}

async function removeUsermediaFromLibrary(usermediaID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/${usermediaID}`, {
            method: 'DELETE',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to delete media from library: ${error.message}`)
    }
}



// ------------------------------------------------------------------------- CATALOG


async function getCatalogPage(pageNumber, pageSize, token) {
    // gets page for catalog
    try {
        const response = await fetch(`${SERVER_URL}/medias/catalog?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get catalog page: ${error.message}`);
    }
}

async function getCatalogTypePage(pageNumber, pageSize, type, token) {
    // gets page for catalog
    try {
        const response = await fetch(`${SERVER_URL}/medias/catalog/type?pageNumber=${pageNumber}&pageSize=${pageSize}&type=${type}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get catalog type page: ${error.message}`);
    }
}


async function getGenreCatalogPage(pageNumber, pageSize, genreID, token) {
    // gets genre page for catalog
    try {
        const response = await fetch(`${SERVER_URL}/medias/catalog/${genreID}?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get genre catalog page: ${error.message}`);
    }
}

async function getGenreCatalogTypePage(pageNumber, pageSize, genreID, type, token) {
    // gets genre page for catalog
    try {
        const response = await fetch(`${SERVER_URL}/medias/catalog/type/${genreID}?pageNumber=${pageNumber}&pageSize=${pageSize}&type=${type}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get genre catalog type page: ${error.message}`);
    }
}

async function addBook(bookPostModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/books`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(bookPostModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post book: ${error.message}`)
    }
}



// ------------------------------------------------------------------------- COVERS


async function postCover(mediaID, image, token) {
    try {
        const response = await fetch(`${SERVER_URL}/medias/cover/${mediaID}`, {
            method: 'POST',
            headers: {'Content-Type': 'image/jpeg', "Authorization": `Bearer ${token}`}, 
            body: image
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post cover: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- GENRE

async function getGenres(token) {
    try{
        const response = await fetch(`${SERVER_URL}/genres`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get genres: ${error.message}`)
    }
}


// ------------------------------------------------------------------------- AUTHOR


async function postCreator(postModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/creators`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(postModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post author: ${error.message}`)
    }

}

// ------------------------------------------------------------------------- SERIES

async function postSeries(seriesName, token) {
    try {
        const response = await fetch(`${SERVER_URL}/series`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: seriesName
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post series: ${error.message}`)
    }
}


// ------------------------------------------------------------------------- TAGS
async function getTags(token) {
    // gets tags WITHOUT attached media 
    try {
        const response = await fetch(`${SERVER_URL}/tags`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get tags: ${error.message}`)
    }
}

async function getTagsWithAttachedMedia(token) {
    // gets tags WITH attached media
    try {
        const response = await fetch(`${SERVER_URL}/tags/all`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get tags: ${error.message}`)
    }
}

async function getTag(tagID, token) {
    // gets tag by ID
    try {
        const response = await fetch(`${SERVER_URL}/tags/${tagID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get tag ${tagID}: ${error.message}`)
    }
}

async function postTag(tagValue, token) {
    // adds tag to tag table
    try {
        const response = await fetch(`${SERVER_URL}/tags`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: tagValue
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post tag: ${error.message}`)
    }
}

async function removeTagFromUsermedia(usermediaID, tagToRemove, token) {
    // removes tag from book
    try {
        const response = await fetch(`${SERVER_URL}/usermedias/${usermediaID}/tags`, {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`},
            body: tagToRemove
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to remove tag: ${error.message}`)
    }
}

async function deleteTag(tagID, token) {
    // removes tag from tags table
    try {
        const response = await fetch(`${SERVER_URL}/tags/${tagID}`, {
            method: 'DELETE',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to remove tag: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- READINGS

async function postReading(reading, token) {
    try {
        const response = await fetch(`${SERVER_URL}/readings`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(reading)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post reading: ${error.message}`)
    }
}

async function endReading(usermediaID, readingEndModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/readings/end/${usermediaID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(readingEndModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to end reading: ${error.message}`)
    }    
}

async function patchReading(readingID, readingPatchModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/readings/${readingID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(readingPatchModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch reading: ${error.message}`)
    }    
}

async function deleteReading(readingID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/readings/${readingID}`, {
            method: 'DELETE',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to remove tag: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- SEARCH

async function getFromSearch(search, token) {
    // limited access
    try {
        const response = await fetch(`${SERVER_URL}/search`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(search)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to find items from search: ${error.message}`)
    }
}


async function getFromSearchAllAccess(search, token) {
    // all access
    try {
        const response = await fetch(`${SERVER_URL}/search/allaccess`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(search)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to find items from search: ${error.message}`)
    }
}

async function searchLibrary(search, token) {
    try {
        const response = await fetch(`${SERVER_URL}/search/library`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(search)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to find items from search: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- ENTRIES

async function getEntriesForUsermedia(usermediaID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/entries/${usermediaID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get entry: ${error.message}`)
    }

}

async function postEntry(entry, usermediaID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/entries/${usermediaID}`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(entry)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to find entry: ${error.message}`)
    }
}

async function patchEntry(entryPatchModel, entryID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/entries/${entryID}`, {
            method: 'PATCH',
            headers: {'Content-Type': 'application/json', "Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(entryPatchModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to patch entry: ${error.message}`)
    }
}

async function deleteEntry(entryID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/entries/${entryID}`, {
            method: 'DELETE',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to remove entry: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- RECOMMENDATIONS

async function postRecommendation(recommendationPostModel, token) {
    try {
        const response = await fetch(`${SERVER_URL}/recommendations`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',"Authorization": `Bearer ${token}`}, 
            body: JSON.stringify(recommendationPostModel)
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to post recommendation: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- LOGIN

async function postLogin(credentials) {
    try {
        const response = await fetch(`${SERVER_URL}/sessions`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify(credentials)
        });
        return handleResponse(response);
    } catch (error) {
        console.log(error);
        throw new Error(`Failed to post reading: ${error.message}`)
    }
}

async function postNewUser(credentials) {
    try {
        const response = await fetch(`${SERVER_URL}/users`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}, 
            body: JSON.stringify(credentials)
        });
        return handleResponse(response);
    } catch (error) {
        console.log(error);
        throw new Error(`Failed to post reading: ${error.message}`)
    }
}

async function getUser(userID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/users/${userID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get user: ${error.message}`)
    }
}

async function verifyToken(token) {
    try {
        const response = await fetch(`${SERVER_URL}/users/verification`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed verify token: ${error.message}`)
    }
}

async function requestPasswordReset(email) {
    try {
        const response = await fetch(`${SERVER_URL}/users/passwordreset`, {
            method: 'POST',
            body: email
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed  create reset token: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- OPEN LIBRARY

async function getBookFromOpenLibrary(input, token) {
    try {
        const response = await fetch(`${SERVER_URL}/externalbooks/openlibrary?searchString=${input}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get book from open library: ${error.message}`)
    }
}

async function getWorkInfoFromOpenLibrary(OLWorkID, token) {
    try {
        const response = await fetch(`${SERVER_URL}/externalbooks/openlibrary/${OLWorkID}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get work from open library: ${error.message}`)
    }
}

// ------------------------------------------------------------------------- GOOGLE BOOKS

async function getBooksFromGoogle(input, token) {
    try {
        const response = await fetch(`${SERVER_URL}/externalbooks/google?searchString=${input}`, {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        });
        return handleResponse(response);
    } catch (error) {
        throw new Error(`Failed to get books from google: ${error.message}`)
    }
}


// ------------------------------------------------------------------------- HANDELER

async function handleResponse(response) {
    if(!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`)
    }
    const responseType = response.headers.get('content-type');

    switch (responseType) {
        case 'application/json':
            return response.json();
        case 'text/plain;charset=UTF-8':
            return response.text();
        case 'application/json; charset=UTF-8':
            // return type of google books API
            return response.json();
        case `application/octet-stream`:
            return response;
        default:
            return null;
    }
}

export {  
    // MEDIA
    getMediaByLibrary, getMediaByID, getMediaPage,
    getCatalogPage, getGenreCatalogPage, 
    getCatalogTypePage, getGenreCatalogTypePage,
    getGenres,
    getCover,
    getSerieByID, getCreator,

    // USERMEDIA
    getTotalItems, getTotalTypeItems,
    getMediaTypePage, 
    getFromSearch, searchLibrary, getFromSearchAllAccess, 
    getUsermediaByID,
    patchUsermedia, patchUsersubmedia, 
    addMediaToLibrary, removeUsermediaFromLibrary,
    getTags, getTag, getTagsWithAttachedMedia, putTagOnMedia, postTag, removeTagFromUsermedia, deleteTag,
    postReading, endReading, patchReading, deleteReading,
    getEntriesForUsermedia, postEntry, patchEntry, deleteEntry,
    postRecommendation,
    // LOG IN
    postLogin, getUser, postNewUser, verifyToken, requestPasswordReset,

    // ADDING DATA
    postSeries, postCreator, postMedia, 
    patchMedia, postCover,


    // TO DO: FIX ADD MEDIA TO DATABASE & EXTERNAL BOOKS
    getBookFromOpenLibrary, getWorkInfoFromOpenLibrary, getBooksFromGoogle,
    addBook
    };
