import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./SearchBook.module.css";
import { Cover } from "../../../Cover/Cover";
import { Author } from "../../../Author/Author";


export const SearchBook = (props) => {

    // props:
    //
    // mediaItem - object containing book information
    // active - bool, indicates if book is selected or not

    // ----------------------------------------------------- PRE-RENDER
    
    // ----------------------------------------------------- PRE-RENDER
    const bookPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;

    // ----------------------------------------------------- RENDER
    return (
        <section className={props.active ? styles.containerActive : styles.container}>
            <Link to={bookPath} className={styles.link}>
                <div className={styles.content}>
                    <Cover mediaItem={props.mediaItem} size="S" loc="searchBook" />
                    <div className={styles.bookInfo}>
                        <h2 className={styles.bookTitle}>{props.mediaItem.title}</h2>
                        {props.mediaItem.series != null ?
                            <div>
                                <h3 className={styles.bookSeries}>{props.mediaItem.series?.name} #{props.mediaItem.seriesNum}</h3>
                            </div> :
                            null}
                        <Author authorsList={props.mediaItem.creators} size={0} link={false} />
                    </div>

                </div>
            </Link>
        </section>

    );
}