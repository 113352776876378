
// List of supported media types
let SupportedTypesDisplayValuesPlural = [
    "Albums",
    "Books",
    "Movies",
    "TV Shows",
    "Video Games"
]

let SupportedTypesDisplayValuesSingular = [
    "Album",
    "Book",
    "Movie",
    "TV Show",
    "Video Game"
]

// Dictionary mapping of display types to backend values
let SupportedTypesDictionaryMappings = {
    "TV Shows": "tvshow",
    "Books": "book",
    "Albums": "album",
    "Movies": "movie",
    "Video Games": "videogame"
}

let SupportedTypesDictionaryMappingsSingular = {
    "tvshow" : "TV Show",
    "album" : "Album",
    "movie" : "Movie",
    "book" : "Book",
    "videogame" : "Video Game"
}

export {
    SupportedTypesDisplayValuesPlural,
    SupportedTypesDisplayValuesSingular,
    SupportedTypesDictionaryMappings,
    SupportedTypesDictionaryMappingsSingular
}