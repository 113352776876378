import React from "react";
import { Link } from "react-router-dom";
import styles from "./EditionBox.module.css";

import { useMedia } from "../../contexts/MediaContext";


export const VideogameEditionBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    
    let tokenPeices = token.split(".");
    let idAndRole = atob(tokenPeices[1]);
    let role = idAndRole.split(",")[1];

    const mediaItem = useMedia();

    // ----------------------------------------------------- PRE-RENDER
    // formats date
    let date = "";
    if (mediaItem && mediaItem.publication) {
        const temp = new Date(mediaItem.publication)
        date = new Date(temp.getTime() - temp.getTimezoneOffset() * -60000).toLocaleString('en-us', { day: 'numeric', month: 'long', year: 'numeric' });
    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div>
                <h4 className={styles.header}>Published:</h4>
                <div className={styles.value}>{date}</div>

                <h4 className={styles.header}>Model:</h4>
                <div className={styles.value}>{mediaItem.seasons === 1 ? "Single Player" : "Multi-Player"}</div>

                {mediaItem.genres.length > 0 ?
                    <div>
                        <h4 className={styles.header}>Genre:</h4>
                        <div className={styles.genreList}>{mediaItem.genres.map((g) => <div className={styles.genre} key={g.genreID}>{g.genreNAME}</div>)}</div>
                    </div>
                    :
                    null
                }
            </div>
            {
                role === "admin" || role === "librarian" ?
                    <div className={styles.editMediaContainer}>
                        <Link to="/editmedia">
                            <img src="/assets/images/editFile.svg" className={styles.icon} alt="Edit Media Button" title="Edit Media" />
                        </Link>
                    </div>
                    :
                    null
            }

        </section>
    )
}