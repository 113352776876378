import React from "react";
import styles from "./CreatorSuggestion.module.css";

export const CreatorSuggestion = (props) => {

    // props:
    // 
    // creatorItem - object containing author information
    // setSelectedCreator - state var setter to let parent know about click

    // ----------------------------------------------------- STATE AND HOOKS
    // ----------------------------------------------------- PRE-RENDER

    const authorDisplayString = props.creatorItem.last + ", " + props.creatorItem.first;
    let mediaContainedIn = [];

    if (props.creatorItem.booksByAuthor) {
        for (let i = 0; i < props.creatorItem.booksByAuthor.length; i++) {
            mediaContainedIn.push(<h5 key={i} className={styles.booksByAuthor}>{props.creatorItem.booksByAuthor[i].title}</h5>)
        }
    }
    
    function handleClick() {
        // handles click if object is clickable
        if (props.setSelectedCreator) props.setSelectedCreator(props.creatorItem);
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container} onClick={handleClick}>
            <div className={styles.content}>
                <div className={styles.authorInfo}>
                    <h2 className={styles.authorName}>{authorDisplayString}</h2>
                    {mediaContainedIn}
                </div>
            </div>
        </section>
    )
}