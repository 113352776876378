import React, { useEffect, useState } from "react";
import styles from "./Journal.module.css";

import { getEntriesForUsermedia, postEntry } from "../../api/Api";
import { Entry } from "../Entry/Entry";
import { NewEntry } from "../Entry/NewEntry";
import { EntryReadingHeader } from "../Entry/EntryReadingHeader";
import { useMedia, useMediaUpdate } from "../../contexts/MediaContext";

export const Journal = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token");

    const mediaItem = useMedia();
    const setMedia = useMediaUpdate();

    // const entries = fetchEntries();

    const [isAdding, setIsAdding] = useState(false); // states if new entry is being created
    const [newEntry, setNewEntry] = useState(null); // populated by NewEntry when entry is submitted
    const [editedEntry, setEditedEntry] = useState(null) // populates with updated entry when edited

    const entryObjects = makeEntryObjects();

    useEffect(() => {
        if (newEntry !== null) {
            addEntry();
        }
    },[newEntry]);

    // useEffect(() => {
    //     if (editedEntry !== null) {
    //         fetchEntries();
    //     }
    // }, [editedEntry]);


    useEffect(() =>{
        // triggers re-render when media item changes
    },[mediaItem])


    // ----------------------------------------------------- PRE-RENDER

    // async function fetchEntries() {
    //     // gets entry from back

    //     return await getEntriesForUsermedia(mediaItem.usermediaID, token)
    //         .then((foundEntries)=>{
    //             // setEntries(foundEntries);
    //             return foundEntries;
    //         })
    //         .catch((error) => {
    //             console.log("failed to fetch entries");
    //             console.log("error",error);
    //         })
    // }

    async function addEntry() {
        // adds entry to back
        let entryPostModel = {
            title: newEntry.title,
            body : newEntry.body,
            time : new Date,
            page : newEntry.page,
            readingID : newEntry.readingID
        }

        await postEntry(entryPostModel, mediaItem.usermediaID, token)
            .then((media) => {
                setNewEntry(null) // clears newEntry
                setIsAdding(false); // closes new post adder
                // fetchEntries(); // re-fetches entries for ordering
                setMedia(media);
                
            })
            .catch((error) =>{
                console.log("failed to add entry", error);
            })
    }

    // function updateEntry(){
    //     // finds and replaces edited entry, remakes entry objects

    //     for (let i = 0; i < entries.length; i++) {
    //         if (entries[i].id == editedEntry.id) {
    //             entries[i] = editedEntry; // replaces entry
    //             setEditedEntry(null); // clears editedEntry
    //             makeEntryObjects(entries); // remakes objects
    //         }
    //     }
    // }

    function makeEntryObjects() {
        // makes entry objects
        if (mediaItem.entries === null) return null;
        
        var tempEntryObjects = []; 
        var currentReading = 0;
        var seenNull = false;

        for (let i = 0; i < mediaItem.entries.length; i++) {
            // make header object if needed
            if (mediaItem.entries[i].readingID === null) {
                // no reading associated with entry
                if (!seenNull) { // already made null header
                    tempEntryObjects.push(<div key={"header" + i}><EntryReadingHeader readingItem={mediaItem.entries[i].readingID} mediaItem={mediaItem} /></div>);
                    seenNull = true;
                }
            } else if (mediaItem.entries[i].readingID.id !== currentReading) { 
                // reading header has not been made yet
                currentReading = mediaItem.entries[i].readingID.readingID;
                tempEntryObjects.push(<div key={"header"+i}><EntryReadingHeader readingItem = {mediaItem.entries[i].readingID} mediaItem = {mediaItem}/></div>);
            }

            // makes entry object
            tempEntryObjects.push(<div key={mediaItem.entries[i].entryID}><Entry entryItem={mediaItem.entries[i]} setEdited={setEditedEntry} /></div>);
        }
        return tempEntryObjects; 
    }

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <div className={styles.header}>
                <span><h3 className={styles.headerTitle}>Journal</h3></span>
                <button className={styles.plusButton} onClick={ () => setIsAdding(!isAdding) }>
                    <img src="/assets/images/plusIcon.svg" alt="add icon" title="New Entry" className={styles.icon}/>
                </button>
            </div>
            <div className={styles.content}>
                {isAdding ? <NewEntry mediaItem={mediaItem} setNewEntry={setNewEntry} setIsAdding={setIsAdding} /> : null}
                {entryObjects}
            </div>
            
        </section>
    )
}