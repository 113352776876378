// handles errors
// logs user out if token is expired

function ErrorHandler(error) {

    // props:
    // 
    // error = error object
    
    const handledError = handler(error);

    switch(handledError) {
        case "login" :
            localStorage.removeItem('token');
            window.location.reload();
            break;
        default:
            console.log(error);
    }

    return null;
}


function handler(error) {
    // determins which type of error has been supplied

    if (error === undefined || error === null) return null; // no error supplied

    if (error.message === "Request failed with status: 403") {
        // bad token
        return "login"
    } else {
        // unaccounted for error
        return null 
    }

}

export default ErrorHandler;